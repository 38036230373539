import { SET_SUMMARY, LOGOUT, CLEAR_SUMMARY } from "../actions/type";

var initailState={};

const summary =( state = initailState , action) =>{
    switch (action.type) {
        case SET_SUMMARY:
            return {
                ...state,
                ...action.payload,
            };
        case CLEAR_SUMMARY:
            return initailState;
        case LOGOUT:
            return initailState;
        default:
            return state;
    }
};

export default summary;