import React ,{useState , useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';

import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { connect } from "react-redux";
import {setDistributor} from "../../../actions/distributorAction";

import SweetAlert from "react-bootstrap-sweetalert";
import Backbutton from "../Backbutton";


let schema = yup.object({
  name:yup.string().required("Name is Required"),
  username:yup.number().positive().integer().required("Username is Required").typeError('Username Must be of Number'),
  commission:yup.number().positive().integer().required("Commission is Required").typeError('Commission Must be of Number'),
  dist:yup.string().required("Distributor is Required"),
  //amounttrans:yup.string().required("Amount is required"),
  password:yup.string().required("Password is Required")
  .min(5, ({ min }) => `Password must be at least ${min} characters`)

});

function RetailerAdd({server , history , access_token,distributor , setDistributor , role}) {
  const [alert, setAlert] = useState(null);
  const [selectData, setselectData] = React.useState('');
  const [errortext , seterrortext] = useState('');

  useEffect(()=>{
    if(role?.id !== 1){
      if(role?.id !== 2){
        if(role?.id !== 3){
          if(role?.id === 4){
            history.push("/Games");
          }
        }
      }
    }
  },[])


  const hideAlert = () => {
    setAlert(null);
  };
  const handleChanges = (event) => {
    setselectData(event.target.value);
  };

  const selectdatas = 
  distributor.map(select => ({value: select.id, label: select.username }));

  const filterd =
  distributor.filter(distributor =>  distributor.id == role?.pivot?.user_id).map(select => ({value: select.id ,label:select.username}))


  const added = () => {
    console.log("added call");
    setAlert(
      <SweetAlert success title="Added" onConfirm={hideAlert}>
        User has been created
      </SweetAlert>
    );
  };
  const addValue =(values, resetForm) =>{
    const data={
      name:values.name,
      password:values.password,
      username:values.username,
      commission:values.commission,
      //amount:values.amounttrans,
      distributor:values.dist,
    }
    console.log(data);

    const config ={
      headers:{Authorization :`Bearer ${access_token}`}
    };

    axios
    .post(server + "api/register/retailor", data , config)
    .then(response =>{
      console.log(response);
      console.log("submitted");
      added();
      resetForm(); 
      seterrortext('');    
    })
    .catch(error =>{
      console.log(error.response);
      const errormessage = error.response.data.errors.username[0];
      console.log(errormessage);
      seterrortext(errormessage);
    })
  };

  const distfetching = () => {
    console.log("use effect running");

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get(server + "api/distributor", config)
      .then((response) => {
        console.log("get data");
        // console.log(response.data.map((object)=> {
        //  return [object.id , object.name , object.username]
        //  console.log(...object);
        // }));
        console.log(response.data);
        setDistributor(response.data); //setActivities is redux object.
        console.log(distributor);
      })
      .catch((error) => {
        console.log(error);
      });
  };

    useEffect(() => {
      distfetching();
    }, []);
  return (
    <div className="grayBackgroundBox">
      {alert}
      <div className="card container">
      <div>
          <Backbutton />
        </div>
      <Formik
      initialValues={{username :'', name:'', commission:'', password:'', dist:''}}
      validationSchema={schema}
      onSubmit={(values, {resetForm})=>{
        console.log(values);
        addValue(values , resetForm)
      }}
      >
      {({values, handleBlur , handleChange, handleSubmit, errors, touched, isSubmitting, isValid})=>(
        <form onSubmit={handleSubmit} className="container">
          <div className="title">ADD NEW RETAILER</div>
          <div>
            <div className="row">
              <div className="col-12 col-md-6 inputFieldContainer">
                <TextField
                  className="inputField"
                  id="standard-basic"
                  label="Username"
                  name="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username} />
                  {(errors.username) &&
                  <p style={{color:"red"}}>{errors.username}</p>}
                  {errortext && (
                  <p style={{color:"red"}}>{errortext}</p>
                  )}
              </div>
              <div className="col-12 col-md-6 inputFieldContainer">
                <TextField
                  className="inputField"
                  id="standard-basic"
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name} />
                  {(errors.name && touched.name) &&
                  <p style={{color:"red"}}>{errors.name}</p>}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 inputFieldContainer">
                <TextField
                  className="inputField"
                  id="standard-basic"
                  label="Commission"
                  name='commission'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.commission} />
                  {(errors.commission && touched.commission) &&
                  <p style={{color:"red"}}>{errors.commission}</p>}
              </div>
              <div className="col-12 col-md-6 inputFieldContainer">
                <TextField
                  className="inputField"
                  id="standard-password-input"
                  type="password"
                  label="Password"
                  autoComplete="current-password"
                  name='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password} />
                  {(errors.password && touched.password) &&
                  <p style={{color:"red"}}>{errors.password}</p>}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 inputFieldContainer">
              {role?.id === 3 ?
                (
                <TextField className='inputField' id="standard-basic" 
                select
                label="Distributor"
                name='dist'
                onChange={(event)=>{
                  handleChange(event);
                  handleChanges(event);
                }}
                onBlur={handleBlur} 
                value={selectData}>
                  {filterd.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
                ))}
                </TextField>
                ):
                (
                <TextField className='inputField' id="standard-basic" 
                select
                label="Distributor"
                name='dist'
                onChange={(event)=>{
                  handleChange(event);
                  handleChanges(event);
                }}
                onBlur={handleBlur} 
                value={selectData}>
                  {selectdatas.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
                ))}
                </TextField>
                )
              }
                  {(errors.dist && touched.dist) &&
                  <p style={{color:"red"}}>{errors.dist}</p>}
              </div>
              {/* <div className="col-12 col-md-6 inputFieldContainer">
                <TextField
                  className="inputField"
                  id="standard-basic"
                  label="Amount to transfer"
                  name="amounttrans"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.amounttrans}/>
                  {(errors.amounttrans && touched.amounttrans) &&
                  <p style={{color:"red"}}>{errors.amounttrans}</p>}
              </div> */}
            </div>
          </div>
          <div className="text-center mt-5">
            <Button
              className="formSubmit"
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
              disabled={!isValid}
            >
              Save Retailer
            </Button>
          </div>
        </form>
      )}
      </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  distributor: state.distributor,
  role: state.auth.role[0],

});


export default connect(mapStateToProps,
  {setDistributor})(RetailerAdd);
