import { SET_CURRENT_USER_SUMMARY, CLEAR_CURRENT_USER_SUMMARY,LOGOUT } from "../actions/type";

const initialState = {} 
const currentUser = (state=initialState,action) =>{
    switch(action.type){
        case SET_CURRENT_USER_SUMMARY:
            return {
                ...state,
                ...action.payload
            };
        case CLEAR_CURRENT_USER_SUMMARY:
            return initialState;
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
}

export default currentUser;