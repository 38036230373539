import React, { Component, useEffect , useState} from "react";
//import TextField from "@material-ui/core/TextField";
//import AppBar from 'material-ui/AppBar';
//import RaisedButton from 'material-ui/RaisedButton';
// import TextField from 'material-ui/TextField';
import { Link, useHistory } from "react-router-dom";
import "../../assets/scss/Login.scss";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { ToastContainer, toast } from 'react-toastify';

import { Formik } from 'formik';
import * as yup from 'yup';
import axios from "axios";

import SweetAlert from "react-bootstrap-sweetalert";
import 'react-toastify/dist/ReactToastify.css';

//Redux
import { connect } from "react-redux";
import { login,logout } from "../../actions/authAction";
import {setSuper} from "../../actions/superAction";
import {setDistributor} from "../../actions/distributorAction";

let schema = yup.object({
  username:yup.string().required("Username is Required").nullable(),
  password:yup.string().required("Password is Required").nullable()
});

const Login = ({ history,server,login,role,auth,access_token , superd ,setSuper ,distributor , setDistributor,logout }) => {
  const [alert, setAlert] = useState(null);
  const [checked, setChecked] = React.useState(false);

  const handleCheck = () => {
    checked? setChecked(false) :setChecked(true);
  }
  const classes = useStyles();

  const hideAlert = () => {
    setAlert(null);
  };
 
  const alertcall = (errormessage) => {
    console.log("run on error");
    console.log(errormessage);
    setAlert(
      <SweetAlert
      title="ERROR !"
      onConfirm={hideAlert}
      >
        {errormessage}
      </SweetAlert>
    )
  }

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (!error.response) {
        toast.error("Network Error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        if (error.response.status === 401) {
        logout();
        history.push("/");
        }

        return Promise.reject(error);
      }
    }
  );
  useEffect(() => {
    if(auth)
    {
      console.log(role.id);
      if(role.id===1)
        {
            history.push("/Dashboard");
        }
        else if(role.id===2)
        {
            history.push("/Dashboard");
        }
        else if(role.id===3)
        {
            history.push("/Dashboard");
        }
    }
  }, []);
  const handleLogin = (values) => {
    console.log("Login Funtion");
    console.log(server);
    if(checked)
    {
      localStorage.setItem("username",values.username);
      localStorage.setItem("password",values.password);
    }
    const bodyParameters = {
      username: values.username,
      password: values.password
    };

    axios
      .post(server + "api/login", bodyParameters)
      .then(response => {
        login(response.data);
        if(response.data.role[0].id===1)
        {
            history.push("/Dashboard");
        }
        else if(response.data.role[0].id===2)
        {
            history.push("/Dashboard");
        }
        else if(response.data.role[0].id===3)
        {
            history.push("/Dashboard");
        }
        else if(response.data.role[0].id===4)
        {
          alertcall("Retailer Cannot Login!");
        }
      })
      .catch(function(error) {
        console.log(error);
        //console.log(error.response.data.errors.authError[0]);
        const errormessage = error.response.data.errors.authError[0];
        //console.log(errormessage);
        alertcall(errormessage);
      });

  };

  return (
    <div className={classes.page}>
      {alert}
      <div className={classes.formContainer} >
        <Formik
        initialValues={{username :localStorage.getItem("username"), password:localStorage.getItem("password")}}
        validationSchema={schema}
        onSubmit={(values)=>{
          console.log(values);
          handleLogin(values);
        }}>
        {({handleChange, handleBlur, values, handleSubmit, isValid, errors, touched})=>(
          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
            <h3 style={{marginTop:0}}>Login</h3>
            <TextField className={classes.text} id="standard-basic" label="Username"
            name="username"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.username} />
            {(errors.username && touched.username) &&
            <p style={{color:"red", marginTop:0, fontsize:14}}>{errors.username}</p>} 
            <TextField className={classes.text} id="standard-basic" label="Password" type="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password} />
            {(errors.password && touched.password) &&
            <p style={{color:"red", marginTop:0, fontSize:14}}>{errors.password}</p>}
            <div style={{textAlign:"left",marginLeft:"25px"}}>
              <Checkbox
              checked={checked}
              onChange={handleCheck}
              />
              Remember Me
            </div>
            <Button style={{margin: 20, width: "150px"}} variant="contained" color="white"
            type='submit'
            disabled={!isValid}>
              Login
            </Button>
          </form>
        )}
        </Formik>
      </div>   
    </div>
  );
};

// const style = {
//   margin: 30,
// };

const mapStateToProps = state => ({
  server: state.auth.server,
  auth : state.auth.auth,
  access_token: state.auth.access_token,
  superd: state.superd,
  distributor: state.distributor,
  role: state.auth.role[0],
});

export default connect(
  mapStateToProps,
  { login ,setSuper,setDistributor,logout } //mapActionToProps
)(Login);

const useStyles = makeStyles({
  root: {
    padding: 10,
    color: "black",
  },
  text: {
    width: "250px",
    margin: 10,
    color: "black"
  },
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // background: "url(https://thebigcluster.com/wp-content/uploads/2021/07/Login.jpeg)",
    background: "url(https://slc.thebigcluster.com/images/desktop.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    textAlign: "center",
    margin: "auto",
    minHeight: "100vh",
     minWidth: "100vw",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    // background: '#c3a2c3',
    padding: "25px 25px",
    width: "400px",
    textAlign: "center",
    background: "rgba(137, 139, 42, 0.25)",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 4px )",
    // -webkit-backdrop-filter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba(255, 255, 255, 0.18)",
  }
});