import { SET_COUNT,CLEAR_COUNT,LOGOUT } from "../actions/type";

var initialState = {}
const dashboard = (state=initialState,action) =>{
switch(action.type){
    case SET_COUNT:
        return{
            ...state,
            ...action.payload
        };
    case CLEAR_COUNT:
        return initialState;
    case LOGOUT:
        return initialState;
    default:
        return state;
}
}

export default dashboard;