import {SET_RESULT , LOGOUT , CLEAR_RESULT} from "../actions/type";

var initailState = [];

const resultReducer =( state = initailState , action) =>{
    switch (action.type) {
        case SET_RESULT:
            return [
                ...state,
                action.payload,
            ];
        case CLEAR_RESULT:
            return initailState;
        case LOGOUT:
            return initailState;
        default:
            return state;
    }
};

export default resultReducer;