import React, { useState, useEffect, useMemo,useRef } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Button, Grid, Box,
  FormControl,
  Select,
  InputLabel,
  ListSubheader,
  InputAdornment
} from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Settings from "./Settings";

import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";

import moment from "moment";

import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";

import { setUserSummary } from "../../actions/dashboardAction";

let schema = yup.object({
  date: yup.string().required("Date is Required"),
  time: yup.string().required("Time is Required"),
});
function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const AdminTool = ({
  server,
  access_token,
  role,
  users_summary,
  setUserSummary,
}) => {
  const history = useHistory();
  const [date, setDate] = useState(moment());
  const [time, setTime] = useState(moment());
  const [firstDigit, setFirstDigit] = useState("");
  const [secondDigit, setSecondDigit] = useState("");
  const [lastDigit, setLastDigit] = useState("");
  const [alert, setAlert] = useState(null);
  const [game_names, setGameNames] = useState([]);
  const initialdate = moment();
  const retailers = users_summary.filter((value) => {
    return value.role.name == "Retailor";
  });

  useEffect(() => {
    userSummary();
  }, []);

  useEffect(() => {
    let gameGames = retailers.map((retailer) => {
      return retailer.game_name;
    });
    setGameNames(gameGames);
  }, []);

  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };

  const userSummary = () => {
    const parameter = {
      _method: "post",
    };
    axios
      .post(server + `api/user_summary`, parameter, config)
      .then((response) => {
        console.log(response.data);
        setUserSummary(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (role.id != 1) {
      history.push("/");
    }
    setFirstDigit(firstarray[0]);
    setSecondDigit(secondarray[0]);
    setLastDigit("" + lastarray[0]);
  }, []);
  const handleDate = (event) => {
    setDate(event.target.value);
  };
  const handleTime = (time) => {
    setTime(time);
  };
  const handleFirstDigit = (event) => {
    setFirstDigit(event.target.value);
  };
  const handleSecondDigit = (event) => {
    setSecondDigit(event.target.value);
  };
  // const handleLastDigit = (event) => {
  //   setLastDigit(event.target.value);
  // };
  const hideAlert = () => {
    setAlert(null);
  };
  const added = () => {
    console.log("added call");
    setAlert(
      <SweetAlert success title="Added" onConfirm={hideAlert}>
        Winner Has Been Added.
      </SweetAlert>
    );
  };
  const firstarray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const secondarray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  // const lastarray = [...Array(99).keys()];
  const lastarray = ["00", "01", "02", "03", "04", "05","06", "07","08", "09",
                     "10", "11", "12", "13", "14", "15","16", "17","18","19",
                     "20", "21", "22", "23", "24", "25","26", "27","28","29",
                     "30", "31", "32", "33", "34", "35","36", "37","38","39",
                     "40", "41", "42", "43", "44", "45","46", "47","48","49",
                     "50", "51", "52", "53", "54", "55","56", "57","58","59",
                     "60", "61", "62", "63", "64", "65","66", "67","68","69",
                     "70", "71", "72", "73", "74", "75","76", "77","78","79",
                     "80", "81", "82", "83", "84", "85","86", "87","88","89",
                     "90", "91", "92", "93", "94", "95","96", "97","98","99"];

  const reset = () => {
    setDate(moment());
    setTime(moment());
    setFirstDigit(firstarray[0]);
    setSecondDigit(secondarray[0]);
     setLastDigit(lastarray[0]);
    // setLastDigit("0" + lastarray[0]);
  };
  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () => lastarray.filter((option) => containsText(option, searchText)),
    [searchText]
  );

  const storeGameName = (index, text) => {
    let new_game_names = game_names;
    new_game_names[index] = text;
    console.log(new_game_names);
    setGameNames([...new_game_names]);
  };

  const SaveGameName = (user_id, game_name) => {
    const parameter = {
      _method: "post",
      game_name: game_name,
    };
    axios
      .post(server + `api/game_name_update/` + user_id, parameter, config)
      .then((response) => {
        userSummary();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const focusDiv = useRef();

  useEffect(() => {
   if(focusDiv.current) focusDiv.current.focus(); 
  }, [focusDiv]);

  const addValue = (values, resetForm) => {
    const data = {
      date: formatDate(date),
      time: time.toLocaleString(),
      first_digit: firstDigit,
      second_digit: secondDigit,
      last_2_digit: lastDigit,
    };
    console.log(data);

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post(server + "api/add_winner", data, config)
      .then((response) => {
        console.log(response.data);
        console.log("submitted");
        reset();
        added();
        //setselectData('');
      })
      .catch((error) => {
        console.log(error.response);
        const message = error.response.data.errors[0];
        setAlert(
          <SweetAlert error title="Invalid Request" onConfirm={hideAlert}>
            {message}
          </SweetAlert>
        );
      });
  };
  return (
    <div style={{ width: "100%" }}>
      <div className="grayBackgroundDouble">
        {alert}
        <div className="card container">
          <Formik
            initialValues={{
              date: formatDate(moment()),
              time: moment(),
              first_digit: "",
              second_digit: "",
              last_2_digit: "",
            }}
            validationSchema={schema}
            onSubmit={(values, { resetForm }) => {
              addValue(values, resetForm);
            }}
          >
            {({
              values,
              handleBlur,
              handleChange,
              handleSubmit,
              errors,
              touched,
              isSubmitting,
              isValid,
            }) => (
              <form className="container" onSubmit={handleSubmit}>
                <div className="title">Manual Entry</div>
                <div>
                  <div className="row">
                    <div className="col-12 col-md-6 inputFieldContainer">
                      <TextField
                        id="standard-basic"
                        className="inputField"
                        label="Game Date"
                        type="date"
                        value={formatDate(date)}
                        defaultValue={formatDate(date)}
                        onChange={handleDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ min: formatDate(initialdate) }}
                      />
                      {errors.date && touched.date && (
                        <p style={{ color: "red" }}>{errors.date}</p>
                      )}
                    </div>
                    <div className="col-12 col-md-6 inputFieldContainer">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          className="inputField"
                          margin="normal"
                          id="time-picker"
                          label="Time picker"
                          value={time}
                          onChange={handleTime}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      {errors.time && touched.time && (
                        <p style={{ color: "red" }}>{errors.time}</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 inputFieldContainer">
                      <TextField
                        className="inputField"
                        id="standard-basic"
                        select
                        label="First Digit"
                        name="first_digit"
                        onBlur={handleBlur}
                        onChange={handleFirstDigit}
                        value={firstDigit}
                      >
                        {firstarray.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                      {errors.first_digit && touched.first_digit && (
                        <p style={{ color: "red" }}>{errors.first_digit}</p>
                      )}
                    </div>
                    <div className="col-12 col-md-6 inputFieldContainer">
                      <TextField
                        className="inputField"
                        id="standard-basic"
                        select
                        label="Second Digit"
                        name="second_digit"
                        onBlur={handleBlur}
                        value={secondDigit}
                        onChange={handleSecondDigit}
                      >
                        {secondarray.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                      {errors.second_digit && touched.second_digit && (
                        <p style={{ color: "red" }}>{errors.second_digit}</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div  className="col-12 col-md-6  inputFieldContainer ">
                      {/* <TextField
                        className="inputField"
                        id="standard-basic"
                        select
                        label="Last 2 Digit"
                        name="last_2_digit"
                        onBlur={handleBlur}
                        value={lastDigit}
                        onChange={handleLastDigit}
                      >
                        {lastarray.map((item) => (
                          <MenuItem
                            key={item < 10 ? "0" + item : item}
                            value={item < 10 ? "0" + item : item}
                          >
                            {item < 10 ? "0" + item : item}
                          </MenuItem>
                        ))}
                      </TextField> */}
                      <Box  style={{ marginLeft: "5px"}}  >
                        <FormControl fullWidth className="inputField">
                          <InputLabel id="search-select-label">Last 2 Digit</InputLabel>
                          <Select
                            // Disables auto focus on MenuItems and allows TextField to be in focus
                            MenuProps={{ autoFocus: false }}
                            labelId="search-select-label"
                            id="search-select"
                            value={lastDigit}
                            label="Options"
                            onChange={(e) => setLastDigit(e.target.value)}
                            onClose={() => setSearchText("")}
                            renderValue={() => lastDigit}
                          >
                            <ListSubheader>
                              <TextField
                                size="small"
                                // Autofocus on textfield
                                autoFocus
                                
                                placeholder="Type to search..."
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  )
                                }}
                                onClick={focusDiv}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key !== "Escape") {
                                    // Prevents autoselecting item while typing (default Select behaviour)
                                    e.stopPropagation();
                                  }
                                }}
                              />
                            </ListSubheader>
                            {displayedOptions.map((option, i) => (
                              <MenuItem key={i} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                      {errors.last_2_digit && touched.last_2_digit && (
                        <p style={{ color: "red" }}>{errors.last_2_digit}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="text-center mt-5">
                  <Button
                    className="formSubmit"
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={!isValid}
                    disableElevation
                  >
                    Save
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <Settings />
      <div className="grayBackgroundDouble">
        {alert}
        <div className="card container">
          <div className="title">Add Game Name</div>
          <div>
            {retailers.map((retailer, key) => (
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextField
                    id="standard-basic"
                    disabled
                    // className="inputField"
                    label="Username"
                    value={retailer.username}
                  // onChange={(event) => {
                  //   let input = event.target.value ;
                  //   if( !input || ( input[input.length-1].match('[0-9]') && input[0].match('[1-9]')) )
                  //   {
                  //       handleChange(event);
                  //       handleMax(event);
                  //   }
                  // }}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="standard-basic"
                    // className="inputField"
                    label="Game Name"
                    value={game_names[key]}
                    onChange={(event) => storeGameName(key, event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Tooltip title="Save" placement="top" arrow>
                    <IconButton
                      aria-label="save"
                      style={{
                        backgroundColor: "#FF9800FF",
                        color: "#ffffff",
                        marginTop: "5px",
                      }}
                      className="Save"
                      onClick={() => SaveGameName(retailer.id, game_names[key])}
                    >
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
  users_summary: state.userSummary,
});
export default connect(mapStateToProps, { setUserSummary })(AdminTool);
