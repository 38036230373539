import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import Backbutton from "./Backbutton";
import { connect } from "react-redux";
import { setLimit } from "../../actions/limitAction";
import SweetAlert from "react-bootstrap-sweetalert";

let schema = yup.object({
  limit: yup.number().integer().required("Limit is Required"),
});

const Limit = ({ server, access_token, setLimit, limit }) => {
  const [alert, setAlert] = useState(null);
  const [limits, setLimits] = useState(limit);

  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const added = () => {
    console.log("added call");
    setAlert(
      <SweetAlert success title="Added" onConfirm={hideAlert}>
        Limit Has Been Added.
      </SweetAlert>
    );
  };

  const getLimit = () => {
    axios
      .get(server + "api/limit", config)
      .then((response) => {
        console.log(response.data);
        setLimit(parseInt(response.data.limit));
      })
      .catch((error) => {
        console.log(error.response);
        const message = error.response.data.errors[0];
        setAlert(
          <SweetAlert error title="Invalid Request" onConfirm={hideAlert}>
            {message}
          </SweetAlert>
        );
      });
  };

  useEffect(() => {
    getLimit();
  }, []);

  useEffect(() => {
    setLimits(limit);
  }, [limit]);

  const addLimit = (values, resetForm) => {
    const data = {
      limit: limits,
    };
    console.log(data);
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post(server + "api/limit/add", data, config)
      .then((response) => {
        added();
        getLimit();
        //setselectData('');
      })
      .catch((error) => {
        console.log(error.response);
        const message = error.response.data.errors[0];
        setAlert(
          <SweetAlert error title="Invalid Request" onConfirm={hideAlert}>
            {message}
          </SweetAlert>
        );
      });
  };
  return (
    <div className="grayBackgroundBox">
      {alert}
      <div className="card container">
        <div>
          <Backbutton />
        </div>
        <Formik
          initialValues={{
            limit: limits,
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            addLimit(values, resetForm);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <form className="container" onSubmit={handleSubmit}>
              <div className="title">Add Limit For Reprinting</div>
              <div>
                <div className="row">
                  <div className="col-12 col-md-12 inputFieldContainer">
                    <TextField
                      required
                      id="standard-basic"
                      className="inputField"
                      label="Limit"
                      value={limits}
                      onChange={(event) => {
                        let input = event.target.value;
                        if (
                          !input ||
                          (input[input.length - 1].match("[0-9]") &&
                            input[0].match("[1-9]"))
                        ) {
                          handleChange(event);
                          setLimits(event.target.value);
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.date && touched.date && (
                      <p style={{ color: "red" }}>{errors.date}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center mt-5">
                <Button
                  className="formSubmit"
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={!isValid}
                  disableElevation
                >
                  Add Limit
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  limit: state.limit,
});
export default connect(mapStateToProps, { setLimit })(Limit);
