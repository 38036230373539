import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { TableCell } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import Card from "@material-ui/core/Card";
//accordion
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
//icons
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//redux part
import { connect } from "react-redux";
import { CardContent } from "@material-ui/core";
import { Grid, Paper } from "@material-ui/core";

import { setDashboard, setUserSummary, setCurrentUserSummary } from "../../../actions/dashboardAction";
import { setOnline } from "../../../actions/userOnlineAction";
const Dashboard = ({
  server,
  history,
  access_token,
  setDashboard,
  role,
  dash,
  setOnline,
  online,
  users_summary,
  setUserSummary,
  setCurrentUserSummary,
  current_user,
}) => {
  const classes = useStyles();

  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };

  const userStatus = () => {
    axios
      .get(server + `api/status`, config)
      .then((response) => {
        console.log(response.data);
        setOnline(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCount = () => {
    axios
      .get(server + `api/count_user`, config)
      .then((response) => {
        console.log(response.data);
        setDashboard(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const userSummary = () => {
    const parameter = {
      _method: "post",
    };
    axios
      .post(server + `api/user_summary`, parameter, config)
      .then((response) => {
        console.log(response.data);
        setUserSummary(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const currentUserSummary = () => {
    axios
      .get(server + `api/current_summary`, config)
      .then((response) => {
        setCurrentUserSummary(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCount();
    userStatus();
    userSummary();
    currentUserSummary();
  }, []);

 
  
  const super_dist = users_summary.filter((value) => {
    return value.role.name == "Super Distributor";
  });
  const dist = users_summary.filter((value) => {
    return value.role.name == "Distributor";
  });
  const ret = users_summary.filter((value) => {
    return value.role.name == "Retailor";
  });
  const columns = [
    {
      name: "user",
      label: "Name",
       options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <div
                onClick={() =>
                  history.push("/AccountSummary", {
                    id: tableMeta.rowData[0],
                    username: value?.username,
                    name: value?.name,
                  })
                }
                className={classes.hover}
              >
                {value?.name}
              </div>
            </>
          );
        },
      },
      // options: {
      //   customBodyRender: (value) => value?.name,
      // },
    },
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "user",
      label: "Username",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <div
                onClick={() =>
                  history.push("/AccountSummary", {
                    id: tableMeta.rowData[0],
                    username: value?.username,
                    name: value?.name,
                  })
                }
                className={classes.hover}
              >
                {value?.username}
              </div>
            </>
          );
        },
      },
      
      // options:
      // {
      //   customBodyRender: (value) => value?.username,
      // },
    },
    {
      name: "isOnline",
      label: "Status",
      options: {
        customBodyRender: (value) =>
          value ? (
            <h6 style={{ color: "green" }}>Online</h6>
          ) : (
            <h6 style={{ color: "red" }}>Offline</h6>
          ),
      },
    },
    {
      name: "last_seen",
      label: "Last Seen",
      options: {
        filter: false,
        customBodyRender: (value) =>
          moment(new Date(value)).format("Do MMMM YYYY h:mm"),
      },
    },
  ];


  const user_columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <div
                onClick={() =>
                  history.push("/AccountSummary", {
                    id: tableMeta.rowData[0],
                    username: tableMeta.rowData[2],
                    name: value,
                  })
                }
                className={classes.hover}
              >
                {value}
              </div>
            </>
          );
        },
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <div
                onClick={() =>
                  history.push("/AccountSummary", {
                    id: tableMeta.rowData[0],
                    username: value,
                    name: tableMeta.rowData[1],
                  })
                }
                className={classes.hover}
              >
                {value}
              </div>
            </>
          );
        },
      },
    },
    {
      name: "total_sale",
      label: "Total Sale",
      options: {
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
    {
      name: "wins",
      label: "Total Win",
      options: {
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
    {
      name: "balance",
      label: "Balance Pending",
      options: {
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
    {
      name: "amount_transfered",
      label: "Amount Transfered",
      options: {
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
    {
      name: "user_commission",
      label: "User Commission",
      options: {
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
    {
      name: "total_commission",
      label: "Total Commission",
      options: {
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
    {
      name: "final",
      label: "Final",
      options: {
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
  ];
  const options = {
    filterType: "dropdown",
    selectableRows: false,
    print: false,
    download: false,
    viewColumns: false,
  };

  return (
    <div className="dashboardMainDivContainer">
      <div className="card m-3 mt-5">
        <div className="titles">Dashboard</div>
        <Card className={classes.card}>
          <Grid container>
            {role.id == 1 ? (
              <Grid item className={classes.item}>
                <CardContent>
                  <AccountCircleIcon
                    className={classes.icon}
                    onClick={() => history.push("/SuperDistributer")}
                    style={{ color: "#1B97F5", backgroundColor: "#E6F4FF" }}
                  />
                  <h3>{dash.super}</h3>
                  <h6 className={classes.sub}>Super Distributors</h6>
                </CardContent>
              </Grid>
            ) : null}
            {role.id <= 2 ? (
              <Grid item className={classes.item}>
                <CardContent>
                  <PermIdentityIcon
                    className={classes.icon}
                    onClick={() => history.push("/Distributer")}
                    style={{ color: "#FDC90F", backgroundColor: "#FFF4E5" }}
                  />
                  <h3>{dash.dist}</h3>
                  <h6 className={classes.sub}>Distributors</h6>
                </CardContent>
              </Grid>
            ) : null}
            {role.id <= 3 ? (
              <Grid item className={classes.item}>
                <CardContent>
                  <SupervisorAccountIcon
                    className={classes.icon}
                    onClick={() => history.push("/Retailer")}
                    style={{ color: "#FC758E", backgroundColor: "#FDF3F5" }}
                  />
                  <h3>{dash.retailer}</h3>
                  <h6 className={classes.sub}>Retailer</h6>
                </CardContent>
              </Grid>
            ) : null}
          </Grid>
        </Card>
        {role.id != 1 ? (
          <div style={{ marginTop: "40px" }}>
            <Grid container item md={12}>
              <Grid item xs={12} md={3} className={classes.bal}>
                <Paper className={classes.paper} style={{ backgroundColor: "#C8FACD" }}>
                  <div className={classes.text} style={{ color: "#005249" }}>Total Sale</div>
                  <div className={classes.amt} style={{ color: "#005249" }}>{parseInt(current_user.total_sale * 100) / 100}</div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} className={classes.bal}>
                <Paper className={classes.paper} style={{ backgroundColor: "#D0F2FF" }}>
                  <div className={classes.text} style={{ color: "#04297A" }}>Balance Pending</div>
                  <div className={classes.amt} style={{ color: "#04297A" }}>{parseInt(current_user.balance * 100) / 100}</div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} className={classes.bal}>
                <Paper className={classes.paper} style={{ backgroundColor: "#FFF7CD" }}>
                  <div className={classes.text} style={{ color: "#7A4F01" }}>Amount Transfered</div>
                  <div className={classes.amt} style={{ color: "#7A4F01" }}>{parseInt(current_user.amount_transfered * 100) / 100}</div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} className={classes.bal}>
                <Paper className={classes.paper} style={{ backgroundColor: "#FFE7D9" }}>
                  <div className={classes.text} style={{ color: "#7F1434" }}>Commission</div>
                  <div className={classes.amt} style={{ color: "#7F1434" }}>{parseInt(current_user.user_commission * 100) / 100}</div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        ) : null}
        <div style={{ marginTop: "40px" }}>
          {role.id == 1 ? (
            <Accordion defaultExpanded>
              <AccordionSummary
                className={classes.heading}
                expandIcon={<ExpandMoreIcon />}
              >
                User Status
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.col}>
                  <MUIDataTable
                    title={"User Status"}
                    data={online}
                    columns={columns}
                    options={options}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          ) : null}
        </div>
        {super_dist.length != 0 ? (
          <div style={{ marginTop: "40px" }}>
            <Accordion defaultExpanded>
              <AccordionSummary
                className={classes.heading}
                expandIcon={<ExpandMoreIcon />}
              >
                Super Distributor
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.col}>
                  {/* <MuiThemeProvider theme={getMuiTheme()}> */}
                  <MUIDataTable
                    title={"Today's Summary"}
                    data={super_dist}
                    columns={user_columns}
                    options={options}
                  />
                  {/* </MuiThemeProvider> */}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : null}
        {dist.length != 0 ? (
          <div style={{ marginTop: "40px" }}>
            <Accordion defaultExpanded>
              <AccordionSummary
                className={classes.heading}
                expandIcon={<ExpandMoreIcon />}
              >
                Distributor
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.col}>
                  <MUIDataTable
                    title={"Today's Summary"}
                    data={dist}
                    columns={user_columns}
                    options={options}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : null}
        {ret.length != 0 ? (
          <div style={{ marginTop: "40px" }}>
            <Accordion defaultExpanded>
              <AccordionSummary
                className={classes.heading}
                expandIcon={<ExpandMoreIcon />}
              >
                Retailer
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.col}>
                  <MUIDataTable
                    title={"Today's Summary"}
                    data={ret}
                    columns={user_columns}
                    options={options}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
  dash: state.dashboard,
  online: state.online,
  users_summary: state.userSummary,
  current_user: state.currentUser,
});

const StyledTableCell = withStyles({
  head: {
    backgroundColor: "red",
    color: "black",
    width: 500,
    fontWeight: "bold",
    textAlign: "center",
    borderColor: "black",
    fontSize: 18,
  },
  body: {
    fontSize: 16,
    textAlign: "center",
    border: "none",
  },
})(TableCell);

const useStyles = makeStyles({
  summary: {
    fontWeight: "bold",
  },
  space: {
    wordSpacing: "30px",
  },
  card: {
    display: "flex",
    flex: "1",
    border: "1px solid #E9E9E9",
    flexDirection: "row",
    borderRadius: "15px",
  },
  item: {
    flex: "1",
    width: "200px",
    border: "1px solid #E9E9E9",
    paddingLeft: "20px",
  },
  icon: {
    height: "50px",
    width: "50px",
    borderRadius: "50px",
    cursor: "pointer",
  },
  sub: {
    color: "#8DA9BB",
  },
  col: {
    flexBasis: "100%",
  },
  heading: {
    fontWeight: "bold",
  },
  hover: {
    cursor: "pointer",
    color: "blue"
  },
  bal: {
    // width: "20%",
    // margin: "20px 20px 0px 20px",
  },
  paper: {
    minHeight: "100px",
    // width:"100%"
    margin: "20px 5px 0px 5px",
  },
  text: {
    padding: "1em 0em 1em 1em",
    color: "grey",
    fontWeight: "70",
    textTransform: "uppercase",
  },
  amt: {
    padding: "0em 0em 1em 1em",
  }
});

export default connect(mapStateToProps, {
  setDashboard,
  setOnline,
  setUserSummary,
  setCurrentUserSummary
})(Dashboard);
