import {CLEAR_SUPER , SET_SUPER } from "./type";

export const setSuper = (data) => async (dispatch, getState) =>{
    dispatch({
        type:CLEAR_SUPER,
        payload:data,
    });
    dispatch({
        type:SET_SUPER,
        payload:data,
    })
}
