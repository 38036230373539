import { React } from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AdminPanelSettingsOutlinedIcon from '@material-ui/icons/AdminPanelSettingsOutlined';
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LockIcon from '@material-ui/icons/Lock';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
export const SideBarTabs = 
    [
      {
        title : 'Dashboard',
        path : '/Dashboard',
        icons: <DashboardIcon className="sideBarIcon"/>,
        cName : 'nav-text',
        priority: 4,
      },
      {
        title : 'Super Distributor',
        path : '/SuperDistributer',
        icons: <AccountCircleIcon className="sideBarIcon"/>,
        cName : 'nav-text',
        priority: 2,
      },
      {
        title : 'Distributor',
        path : '/Distributer',
        icons: <PermIdentityIcon className="sideBarIcon" />,
        cName : 'nav-text',
        priority: 3,
      },
      {
        title : 'Retailer',
        path : '/Retailer',
        icons: <PeopleOutlineIcon className="sideBarIcon" />,
        cName : 'nav-text',
        priority: 4,
      },
      {
        title : 'Point Transfer',
        path : '/PointTransfer',
        icons: <AttachMoneyIcon className="sideBarIcon" />,
        cName : 'nav-text',
        priority: 4,
      },
      {
        title : 'My Account',
        path : '/MyAccount',
        icons: <AccountBalanceIcon className="sideBarIcon" />,
        cName : 'nav-text',
        priority: 4,
      },
      {
        title : 'Admin Tool',
        path : '/AdminTool',
        icons: <AdminPanelSettingsOutlinedIcon className="sideBarIcon" />,
        cName : 'nav-text',
        priority: 1,
      },
      {
        title : 'Reprint Limit',
        path : '/ReprintLimit',
        icons: <HighlightOffIcon className="sideBarIcon" />,
        cName : 'nav-text',
        priority: 2,
      },
      {
        title : 'Change Password',
        path : '/Password',
        icons: <LockIcon className="sideBarIcon" />,
        cName : 'nav-text',
        priority: 4,
      },
    ]




