import { SET_ONLINE, CLEAR_ONLINE, LOGOUT } from "../actions/type";
const initialState = [];
const useronline = (state = initialState, action) => {
  switch (action.type) {
    case SET_ONLINE:
      return [...state, ...action.payload];
    case CLEAR_ONLINE:
      return initialState;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default useronline;
