import React , {useState , useEffect} from 'react'
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import Backbutton from "./Backbutton";
import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

import { connect } from "react-redux";
import {setPoints} from "../../actions/pointAction";
import MenuItem from '@material-ui/core/MenuItem';

import { makeStyles } from '@material-ui/core/styles';
import SweetAlert from "react-bootstrap-sweetalert";

let schema = yup.object({
  username:yup.string().required("Username Is Required"),
  point:yup.string().required("Point Is Required"),
  type:yup.string().required("Type Is Required"),
});


function PointTransferAdd({server , history , access_token, points, setPoints}){
    const [selectData, setselectData] = useState('');
    const [type, setType] = useState('');
    const [alert, setAlert] = useState(null);

    const handleChanges = (event) => {
      setselectData(event.target.value);
    };

    const handleType = (event) => {
      setType(event.target.value);
    };


    const selectdatas = 
    points.map(select => ({value: select.id, label: select.username })); 

    const hideAlert = () => {
      setAlert(null);
    };
    const added = () => {
      console.log("added call");
      setAlert(
        <SweetAlert success title="Added" onConfirm={hideAlert}>
          Transfer Has Been Done.
        </SweetAlert>
      );
    };


    const fetching = () => {
      console.log("use efect running");

      const config = {
        headers: { Authorization: `Bearer ${access_token}` },
      };

      axios
      .get(server +"api/alluser" , config)
      .then((response)=>{
        console.log("getting data");
        console.log(response.data);
        setPoints(response.data);
        console.log(points);
      })
      .catch((error)=>{
        console.log(error);
        console.log(error.response);
      });
    }

    useEffect(()=>{
      fetching();
    },[])
  
    const addValue =(values, resetForm) =>{
      const data={
        username:values.username,
        point:values.point,
        type:values.type,
      }
      console.log(data);

      const config ={
        headers:{Authorization :`Bearer ${access_token}`}
      };

      axios
      .post(server + "api/points", data , config)
      .then(response =>{
        resetForm();
        added();
        setselectData('');
      })
      .catch(error =>{
        const message = error.response.data.errors[0];
        setAlert(
          <SweetAlert error title="Invalid Request" onConfirm={hideAlert}>
            {message}
          </SweetAlert>
        );
      })
    };
  return (
    <div className='grayBackgroundBox'>
      {alert}
      <div className='card container'>
      <div>
          <Backbutton />
        </div>
        <Formik
        initialValues={{username:'', point:'',type:''}}
        validationSchema={schema}
        onSubmit={(values ,{resetForm})=>{
          addValue(values, resetForm);
          console.log(values)
        }}
        > 
        {({values, handleBlur , handleChange, handleSubmit, errors, touched, isSubmitting, isValid})=>(
        <form onSubmit={handleSubmit} className='container'>
          <div className='title'>
            Transfer Point
          </div>
            <div>
                <div className='column'>
                    <div className="inputFieldContainer">
                        <TextField  className='inputField' id="standard-basic" 
                        select
                        label="Username"
                        name="username"
                        onChange={(event)=>{
                          handleChange(event);
                          handleChanges(event);
                        }}
                        onBlur={handleBlur}
                        value={selectData} 
                        >
                          {selectdatas.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                          ))}
                        </TextField>
                        {(errors.username && touched.username) &&
                        <p style={{color:"red"}}>{errors.username}</p>}
                    </div>
                    <div className="inputFieldContainer">
                        <TextField  className='inputField' id="standard-basic" 
                        select
                        label="Type"
                        name="type"
                        onChange={(event)=>{
                          handleChange(event);
                          handleType(event);
                        }}
                        onBlur={handleBlur}
                        value={type} 
                        >
                          <MenuItem key="credit" value="credit">
                            Credit
                          </MenuItem>
                          <MenuItem key="debit" value="debit">
                            Debit
                          </MenuItem>
                  
                        </TextField>
                        {(errors.type && touched.type) &&
                        <p style={{color:"red"}}>{errors.type}</p>}
                    </div>
                    <div className="inputFieldContainer">
                        <TextField  className='inputField' id="standard-basic" 
                        label="Point"
                        name="point"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.point} 
                        />
                        {(errors.point && touched.point) && 
                        <p style={{color:"red"}}>{errors.point}</p>}
                    </div>
                </div>
            </div>
            <div className='text-center mt-5'>
            <Button className='formSubmit' type='submit' variant="contained" color="primary"
            disabled={!isValid}
            disableElevation
            >
                Send Points
            </Button>
            </div>
            </form>
          )}
          </Formik>
        </div>
    </div>
    )
  }


// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(5),
//     minWidth: 380,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(3),
//   },
// }));

const mapStateToProps = state => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  points: state.points,
});


export default connect(mapStateToProps,
  {setPoints})(PointTransferAdd);
