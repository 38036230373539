import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Select from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TodayIcon from "@material-ui/icons/Today";
import EventIcon from "@material-ui/icons/Event";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { Link, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";

// import "../assests/Accounts.css";
import "../assets/css/Accounts.css";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

import { useDispatch, useSelector } from "react-redux";
import { Console } from "console";

import inLocale from "date-fns/locale/en-GB";

const Result = ({ resultClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const server = useSelector((state) => state.auth.server);
  const access_token = useSelector((state) => state.auth.access_token);
  const selectedData = useSelector((state) => state.selected);
  const results = useSelector((state) => state.result);
  const [result, setResult] = useState(
    results.map((item, key) => {
      return Object.values(item);
    })
  );
  const [fromdate, setfromdate] = useState(new Date());
  const [todate, settodate] = useState(new Date());
  const [game_time, setGameTime] = useState(null);
  const [count, setCount] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const game = ["All", 10, 20, 30, 40, 50, 60, 70, 80, 90];
  const [selectedGame, setSelectedGame] = useState();
  const [datas, setData] = useState();
  const series = [10, 20, 30, 40, 50, 60, 70, 80, 90];
  const [selectedSeries, setSelectedSeries] = useState(
    Object.keys(selectedData).length > 0
      ? selectedData.series
      : new Array(3).fill(10)
  );
  const [print_game_time, setPrintGameTime] = useState();

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const handleChange = (event) => {
    setSelectedGame(event.target.value);
  };

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };
    const parameter = {
      _method: "post",
    };

    axios
      .post(server + `api/showresult`, parameter, config)
      .then((response) => {
        console.log(response.data);
        var res = [response.data];
        dispatch({ type: "CLEAR_RESULT", payload: response.data });
        dispatch({ type: "SET_RESULT", payload: response.data });
        setResult(
          res.map((item, key) => {
            return Object.values(item);
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    if (result[0]?.length) {
      if (result?.[0]?.[0].length == 10) {
        setCount([1]);
      } else if (result?.[0]?.[0].length == 1) {
        setData(
          result[0]?.reverse().map((item) => {
            return item[0];
          })
        );
      }
      setPrintGameTime(
        result[0]?.length ? result?.[0]?.[0]?.[0].game_time : ""
      );
    }
    console.log(result[0]);
  }, [result]);

  const handleSelectedSeries = (e, index) => {
    let temp = selectedSeries.map((val, key) => {
      if (key == index) {
        return e.target.value;
      }
      return val;
    });
    setSelectedSeries(temp);
    console.log(temp);
  };

  const getHourandMinutes = (data)=>{
    const date = new Date(data);
    let hours = date.getHours(),
      minutes = date.getMinutes();

      return hours+":"+minutes;
  }
  const resultfetch = () => {
    // formatDate(fromdate._d);
    console.log(game_time);
    console.log(getHourandMinutes(game_time));
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    let parameter;

    if(game_time == null){
       parameter = {
        _method: "post",
        game_date: [formatDate(fromdate), formatDate(todate)],
        game: selectedGame,
      };
    }else{
       parameter = {
        _method: "post",
        game_date: [formatDate(fromdate), formatDate(todate)],
        game_time: getHourandMinutes(game_time),
        game: selectedGame,
      };
    }

    axios
      .post(server + `api/showresult`, parameter, config)
      .then((response) => {
        console.log(response.data);
        var res = [response.data];
        dispatch({ type: "CLEAR_RESULT", payload: response.data });
        dispatch({ type: "SET_RESULT", payload: response.data });
        setResult(
          res.map((item, key) => {
            return Object.values(item);
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const printResults = () => {
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };
    const parameter = {
      _method: "post",
      series: selectedSeries,
      game_time: print_game_time,
    };
    console.log("parameters", parameter);
    dispatch({ type: "CLEAR_SELECTED", payload: { series: selectedSeries } });
    dispatch({ type: "SET_SELECTED", payload: { series: selectedSeries } });
    axios.post(server + `api/print_result`, parameter, config).then((res) => {
      console.log(res.data);
    });
  };

  const columns = [
    {
      name: "game_date",
      label: "Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) =>
          moment(new Date(value)).format("Do MMMM YYYY"),
      },
    },
    {
      name: "game_time",
      label: "Game Time",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "second_digit",
      label: "Game",
      options: {
        display: false,
      },
    },
    {
      name: "first_digit",
      label: "Game",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => value + tableMeta.rowData[2],
      },
    },
    {
      name: "last_2_digit",
      label: "Winner",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: false,
    print: false,
    download: false,
    filter: false,
    sort: false,
    search: false,
    viewColumns: false,
    pagination: false,
    rowsPerPageOptions: [5, 10, 15],
  };

  return (
    <>
      <div className="maindiv">
        <Grid container item md={12} className="Accounts">
          <Grid md={10}>
            <Paper className="Box">
              <Grid container item md={12}>
                <div className="header">RESULT</div>
              </Grid>

              <Grid
                container
                item
                md={12}
                style={{
                  marginTop: "2%",
                }}
              >
                {/* <Grid item className={classes.icon}>
                  <TodayIcon style={{ color: "#0066CC" }} />
                </Grid> */}
                <Grid
                  item
                  md={6}
                  className={classes.start}
                  style={{ padding: "5px" }}
                >
                  <p style={{ color: "#0066CC" }}>Desired Start Date</p>
                  <Datetime
                    id="from"
                    timeFormat={false}
                    value={fromdate}
                    onChange={(date) => setfromdate(date)}
                    inputProps={{
                      label: "Start Date",
                      placeholder: "Date & Time",
                    }}
                  />
                </Grid>
                {/* <Grid item className={classes.icon1}>
                  <EventIcon style={{ color: "#0066CC" }} />
                </Grid> */}
                <Grid
                  item
                  md={6}
                  className="end"
                  style={{ padding: "5px", width: "100%" }}
                >
                  <p style={{ color: "#0066CC" }}>Desired End Date</p>
                  <Datetime
                    timeFormat={false}
                    value={todate}
                    onChange={(date) => settodate(date)}
                    inputProps={{
                      label: "Start Date",
                      placeholder: "Date & Time",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container md={12} >
              <Grid
                  item
                  md={6}
                  xs={12}
                  style={{ padding: "5px" }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={inLocale} >
                    <KeyboardTimePicker
                      className={classes.game}
                      margin="normal"
                      id="game time"
                      label="Game time"
                      type="game"
                      defaultValue={null}
                      initialFocusedDate={null}
                      value={game_time}
                      onChange={(date) => {
                        setGameTime(date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                        shrink: true,
                        // className: classes.text,
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  style={{ padding: "5px" }}
                >
                  <TextField
                    className={classes.game}
                    style={{marginTop:"19px"}}
                    id="standard-basic"
                    label="Game"
                    name="game"
                    size="small"
                    // InputLabelProps={{
                    //   shrink: true,
                    //   // className: classes.text,
                    // }}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    value={selectedGame}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.submit}
                style={{ marginLeft: "12px", textAlign: "center" }}
              >
                <Button
                  onClick={() => resultfetch()}
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor: "rgb(5, 105, 206)",
                    textTransform: "none",
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Paper>

            {result[0]?.length == 0 ? (
              <h3>No Data Found</h3>
            ) : result?.[0]?.[0].length == 1 ? (
              <div style={{ marginTop: "8%" }}>
                <MUIDataTable
                  // style={{marginTop: "20%"}}
                  title={"Result Table"}
                  data={datas}
                  columns={columns}
                  options={options}
                />
              </div>
            ) : (
              result[0]?.map((itr) => (
                <Accordion className="collapsable">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="title">
                      {itr[0].game_time} {formatDate(itr[0].game_date)}
                    </div>
                    <br />
                  </AccordionSummary>
                  {count.map((element) =>
                    itr.map((item, key) =>
                      (item.first_digit == element) &
                      (item.second_digit == 0) ? (
                        <AccordionDetails>
                          <Grid container xs={12}>
                            <Grid item xs={12}>
                              {/* <div className={classes.series}>
                                {item.first_digit + "0"} Series
                              </div> */}
                            </Grid>
                            <Grid item xs>
                              <Paper
                                style={{
                                  borderRadius: 10,
                                  backgroundColor: "#ff6699",
                                  color: "#000",
                                  paddingTop: 10,
                                  paddingBottom: 10,
                                  fontWeight: "bold",
                                }}
                                className={classes.paper}
                              >
                                {String.fromCharCode(
                                  97 + parseInt(item.second_digit)
                                ).toUpperCase() +
                                  item.first_digit +
                                  item.second_digit +
                                  item.last_2_digit}
                              </Paper>
                            </Grid>
                            <Grid item xs>
                              <Paper
                                style={{
                                  borderRadius: 10,
                                  backgroundColor: "#80e5ff",
                                  color: "#000",
                                  paddingTop: 10,
                                  marginLeft: 10,
                                  paddingBottom: 10,
                                  fontWeight: "bold",
                                }}
                                className={classes.paper}
                              >
                                {String.fromCharCode(
                                  97 + parseInt(itr[key + 1].second_digit)
                                ).toUpperCase() +
                                  itr[key + 1].first_digit +
                                  itr[key + 1].second_digit +
                                  itr[key + 1].last_2_digit}
                              </Paper>
                            </Grid>
                            <Grid item xs>
                              <Paper
                                style={{
                                  borderRadius: 10,
                                  backgroundColor: "#1ad1ff",
                                  color: "#000",
                                  paddingTop: 10,
                                  marginLeft: 10,
                                  paddingBottom: 10,
                                  fontWeight: "bold",
                                }}
                                className={classes.paper}
                              >
                                {String.fromCharCode(
                                  97 + parseInt(itr[key + 2].second_digit)
                                ).toUpperCase() +
                                  itr[key + 2].first_digit +
                                  itr[key + 2].second_digit +
                                  itr[key + 2].last_2_digit}
                              </Paper>
                            </Grid>
                            <Grid item xs>
                              <Paper
                                style={{
                                  borderRadius: 10,
                                  background: "#ffb3ff",
                                  color: "#000",
                                  paddingTop: 10,
                                  marginLeft: 10,
                                  paddingBottom: 10,
                                  fontWeight: "bold",
                                }}
                                className={classes.paper}
                              >
                                {String.fromCharCode(
                                  97 + parseInt(itr[key + 3].second_digit)
                                ).toUpperCase() +
                                  itr[key + 3].first_digit +
                                  itr[key + 3].second_digit +
                                  itr[key + 3].last_2_digit}
                              </Paper>
                            </Grid>
                            <Grid item xs>
                              <Paper
                                style={{
                                  borderRadius: 10,
                                  background: "#b3b3ff",
                                  color: "#000",
                                  paddingTop: 10,
                                  marginLeft: 10,
                                  paddingBottom: 10,
                                  fontWeight: "bold",
                                }}
                                className={classes.paper}
                              >
                                {String.fromCharCode(
                                  97 + parseInt(itr[key + 4].second_digit)
                                ).toUpperCase() +
                                  itr[key + 4].first_digit +
                                  itr[key + 4].second_digit +
                                  itr[key + 4].last_2_digit}
                              </Paper>
                            </Grid>
                            <Grid item xs>
                              <Paper
                                style={{
                                  borderRadius: 10,
                                  background: "#c2c2a3",
                                  color: "#000",
                                  paddingTop: 10,
                                  marginLeft: 10,
                                  paddingBottom: 10,
                                  fontWeight: "bold",
                                }}
                                className={classes.paper}
                              >
                                {String.fromCharCode(
                                  97 + parseInt(itr[key + 5].second_digit)
                                ).toUpperCase() +
                                  itr[key + 5].first_digit +
                                  itr[key + 5].second_digit +
                                  itr[key + 5].last_2_digit}
                              </Paper>
                            </Grid>
                            <Grid item xs>
                              <Paper
                                style={{
                                  borderRadius: 10,
                                  background: "#ff80ff",
                                  color: "#000",
                                  paddingTop: 10,
                                  marginLeft: 10,
                                  paddingBottom: 10,
                                  fontWeight: "bold",
                                }}
                                className={classes.paper}
                              >
                                {String.fromCharCode(
                                  97 + parseInt(itr[key + 6].second_digit)
                                ).toUpperCase() +
                                  itr[key + 6].first_digit +
                                  itr[key + 6].second_digit +
                                  itr[key + 6].last_2_digit}
                              </Paper>
                            </Grid>
                            <Grid item xs>
                              <Paper
                                style={{
                                  borderRadius: 10,
                                  background: "#ffccff",
                                  color: "#000",
                                  paddingTop: 10,
                                  marginLeft: 10,
                                  paddingBottom: 10,
                                  fontWeight: "bold",
                                }}
                                className={classes.paper}
                              >
                                {String.fromCharCode(
                                  97 + parseInt(itr[key + 7].second_digit)
                                ).toUpperCase() +
                                  itr[key + 7].first_digit +
                                  itr[key + 7].second_digit +
                                  itr[key + 7].last_2_digit}
                              </Paper>
                            </Grid>
                            <Grid item xs>
                              <Paper
                                style={{
                                  borderRadius: 10,
                                  background: "#ffa64d",
                                  color: "#000",
                                  paddingTop: 10,
                                  marginLeft: 10,
                                  paddingBottom: 10,
                                  fontWeight: "bold",
                                }}
                                className={classes.paper}
                              >
                                {String.fromCharCode(
                                  97 + parseInt(itr[key + 8].second_digit)
                                ).toUpperCase() +
                                  itr[key + 8].first_digit +
                                  itr[key + 8].second_digit +
                                  itr[key + 8].last_2_digit}
                              </Paper>
                            </Grid>
                            <Grid item xs>
                              <Paper
                                style={{
                                  borderRadius: 10,
                                  background: "#adad85",
                                  color: "#000",
                                  paddingTop: 10,
                                  marginLeft: 10,
                                  paddingBottom: 10,
                                  fontWeight: "bold",
                                }}
                                className={classes.paper}
                              >
                                {String.fromCharCode(
                                  97 + parseInt(itr[key + 9].second_digit)
                                ).toUpperCase() +
                                  itr[key + 9].first_digit +
                                  itr[key + 9].second_digit +
                                  itr[key + 9].last_2_digit}
                              </Paper>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      ) : null
                    )
                  )}
                </Accordion>
              ))
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0.5),
    textAlign: "center",
    borderRadius: 0,
    fontWeight: "bold",
  },
  paper1: {
    padding: theme.spacing(0.3),
    textAlign: "center",
    borderRadius: 0,
    fontWeight: "bold",
  },
  series: {
    fontWeight: "bold",
    marginLeft: 10,
  },
  start: {
    width: "100%",
    color: "#0066CC",
  },
  icon: {
    marginTop: "5.2%",
    marginLeft: "10%",
  },
  icon1: {
    marginTop: "5.2%",
    marginLeft: "2%",
  },
  submit: {
    color: "#F189DC",
    marginTop: "5%",
    // marginLeft: "5px",
  },
  game: {
    width: "100%",
    // marginTop: "10px",
    // marginLeft: "3px",
  },
  game1: {
    width: "90%",
    marginTop: "20px",
    // marginLeft: "15px",
  },
  text: {
    width: "100%",
    color: "#0066CC",
    "&.Mui-focused": {
      color: "#0066CC",
    },
  },
  text1: {
    width: "100%",
    color: "#0066CC",
    "&.Mui-focused": {
      color: "#0066CC",
    },
  },
}));
export default Result;
