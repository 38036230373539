import { SET_ACTIVITIES, LOGOUT, CLEAR_ACTIVITIES } from "../actions/type";

var initailState=[];

const activities =( state = initailState , action) =>{
    switch (action.type) {
        case SET_ACTIVITIES:
            return [
                ...state,
                ...action.payload,
            ];
        case CLEAR_ACTIVITIES:
            return initailState;
        case LOGOUT:
            return initailState;
        default:
            return state;
    }
};

export default activities;