import { SET_ONLINE, CLEAR_ONLINE } from "./type";

export const setOnline = (data) => async (dispatch) => {
  dispatch({
    type: CLEAR_ONLINE,
    payload: data,
  });
  dispatch({
    type: SET_ONLINE,
    payload: data,
  });
};
