import {CLEAR_LIMIT , SET_LIMIT } from "./type";

export const setLimit = (data) => async (dispatch, getState) =>{
    dispatch({
        type:CLEAR_LIMIT,
        payload:data,
    });
    dispatch({
        type:SET_LIMIT,
        payload:data,
    })
}
