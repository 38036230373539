import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Backbutton from "./Backbutton"
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";

import { connect } from "react-redux";
import { logout } from "../../actions/authAction";

import { makeStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";

let schema = yup.object().shape({
  password: yup.string().required("This field is required").min(5, ({ min }) => `Password must be at least ${min} characters`),
  confirm: yup.string().required("This field is required").when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: yup
      .string()
      .oneOf([yup.ref("password")], "Both password need to be the same"),
  }).min(5, ({ min }) => `Password must be at least ${min} characters`),
});

function ChangePassword({ server, history, access_token, location }) {
  const [alert, setAlert] = useState(null);
  console.log(location);
  const handleChanges = (values) => {
      let bodyParameters;
      if(location.state)
      {
        bodyParameters = {
            password: values.password,
            user_id:location.state[0].id,
          };
      }
      else
      {
          bodyParameters = {
            password: values.password,
          };
      }
    console.log(bodyParameters);
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };
    axios
      .post(server + "api/reset_password", bodyParameters, config)
      .then((response) => {
        setAlert(
          <SweetAlert success title="Password Changed" onConfirm={hideAlert}>
            your password has been changed.
          </SweetAlert>
        );
        if(location.state)
        {
        }
        else
        {
            history.push("/");
        }
      })
      .catch(function (error) {
        const errormessage = error.response.data.errors.authError[0];
        setAlert(
          <SweetAlert error title="Password Changed" onConfirm={hideAlert}>
            {errormessage}
          </SweetAlert>
        );
      });
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <div className="grayBackgroundBox">
      {alert}
      <div className="card container">
        <div>
          <Backbutton/>
        </div>
        <Formik
          initialValues={{ password: "", confirm: "" }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            handleChanges(values);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <form onSubmit={handleSubmit} className="container">
              <div className="title">Change Password</div>
              <div>
                <div className="column">
                  <div className="inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="New Password"
                      name="password"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {errors.password && touched.password && (
                      <p style={{ color: "red" }}>{errors.password}</p>
                    )}
                  </div>
                  <div className="inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Confirm New Password"
                      name="confirm"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirm}
                    />
                    {errors.confirm && touched.confirm && (
                      <p style={{ color: "red" }}>{errors.confirm}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center mt-5">
                <Button
                  className="formSubmit"
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isValid}
                  disableElevation
                >
                  Change Password
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
});

export default connect(mapStateToProps, {})(ChangePassword);
