import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Backbutton from "./Backbutton";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";


import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";

import SweetAlert from "react-bootstrap-sweetalert";

let schema = yup.object({
  username: yup.string().required("Username Is Required"),
  point: yup.string().required("Point Is Required"),
  type: yup.string().required("Type Is Required"),
});

function PointTransfers({ server, access_token, location }) {
  const [selectData, setselectData] = React.useState("");
  const [type, setType] = useState("");
  const [alert, setAlert] = useState(null);

  console.log(location.state[0].username);
  // const temparray = location.state;
  // console.log(temparray);

  useEffect(() => {
    setselectData(location.state[0].id);
  }, []);

  const filterdata = location.state.map((select) => ({
    value: select.id,
    label: select.username,
  }));
  console.log(filterdata);

  const handleType = (event) => {
    setType(event.target.value);
  };
  const handleChanges = (event) => {
    setselectData(event.target.value);
  };

  const hideAlert = () => {
    setAlert(null);
  };
  const added = () => {
    console.log("added call");
    setAlert(
      <SweetAlert success title="Added" onConfirm={hideAlert}>
        Transfer Has Been Done.
      </SweetAlert>
    );
  };

  const addValue = (values, resetForm) => {
    const data = {
      username: values.username,
      point: values.point,
      type: values.type,
    };
    console.log(data);

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post(server + "api/points", data, config)
      .then((response) => {
        console.log(response.data);
        console.log("submitted");
        resetForm();
        added();
        //setselectData('');
      })
      .catch((error) => {
        console.log(error.response);
        const message = error.response.data.errors[0];
        setAlert(
          <SweetAlert error title="Invalid Request" onConfirm={hideAlert}>
            {message}
          </SweetAlert>
        );
      });
  };
  return (
    <div className="grayBackgroundBox">
      {alert}
      <div className="card container">
        <div s >
          <Backbutton />
        </div>
        <div style={{marginTop:"20px"}}>Total Points: {Math.round(location.state[0].points)}</div>
        <Formik
          initialValues={{ username: location.state[0].id, point: "" }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            addValue(values, resetForm);
            console.log(values);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <form onSubmit={handleSubmit} className="container">
              <div className="title">Transfer Points</div>
              <div>
                <div className="column">
                  <div className="inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      select
                      label="Username"
                      name="username"
                      onChange={(event) => {
                        handleChange(event);
                        handleChanges(event);
                      }}
                      onBlur={handleBlur}
                      value={selectData}
                    >
                      {filterdata.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {errors.username && touched.username && (
                      <p style={{ color: "red" }}>{errors.username}</p>
                    )}
                  </div>
                  <div className="inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      select
                      label="Type"
                      name="type"
                      onChange={(event) => {
                        handleChange(event);
                        handleType(event);
                      }}
                      onBlur={handleBlur}
                      value={type}
                    >
                      <MenuItem key="credit" value="credit">
                        Credit
                      </MenuItem>
                      <MenuItem key="debit" value="debit">
                        Debit
                      </MenuItem>
                    </TextField>
                    {errors.type && touched.type && (
                      <p style={{ color: "red" }}>{errors.type}</p>
                    )}
                  </div>
                  <div className="inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Point"
                      name="point"
                      onChange={(event) => {
                        let input = event.target.value;
                        if (
                          !input ||
                          (input[input.length - 1].match("[0-9]") &&
                            input[0].match("[1-9]"))
                        )
                          handleChange(event);
                      }}
                      onBlur={handleBlur}
                      value={values.point}
                    />
                    {errors.point && touched.point && (
                      <p style={{ color: "red" }}>{errors.point}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center mt-5">
                <Button
                  className="formSubmit"
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isValid}
                  disableElevation
                >
                  Send Points
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
});

export default connect(mapStateToProps, {})(PointTransfers);
