import { SET_USER_SUMMARY, CLEAR_USER_SUMMARY, LOGOUT } from "../actions/type";

const initialState=[]

const userSummary = (state=initialState,action) => {
    switch(action.type){
        case SET_USER_SUMMARY:
            return[
                ...state,
                ...action.payload
            ];
        case CLEAR_USER_SUMMARY:
            return initialState;
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
}
export default userSummary;