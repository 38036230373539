import { SET_ACCOUNT, LOGOUT, CLEAR_ACCOUNT } from "../actions/type";

var initailState=[];

const account =( state = initailState , action) =>{
    switch (action.type) {
        case SET_ACCOUNT:
            return [
                ...state,
                ...action.payload,
            ];
        case CLEAR_ACCOUNT:
            return initailState;
        case LOGOUT:
            return initailState;
        default:
            return state;
    }
};

export default account;