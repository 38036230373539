import React, { useEffect } from "react";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// cor
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

// icons
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// components
import { Link, withRouter } from "react-router-dom";
import { SideBarTabs } from "./SideBarTabs";
import { Tooltip } from "@material-ui/core";

import axios from "axios";
import store from "../../store";

import { connect } from "react-redux";
import { logout } from "../../actions/authAction";
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: "rgba(255, 255, 255, 0.12)",
  },

  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    position: "relative",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  point: {
    color: "white",
    marginLeft: 12,
  },
}));

function MUIDrawer({ history, role, server, access_token, logout, point }) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(matches);

  const handleDrawerClose = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const handleLogOut = () => {
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };
    const parameter = {
      _method: "post",
    };

    console.log("In logout1");
    axios
      .post(server + "api/logout", parameter, config)
      .then((response) => {
        logout();
        history.push("/");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const checkToken = () => {
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };
    console.log("In check");
    axios
      .get(server + "api/time", config)
      .then((response) => {
        console.log(response.data);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          logout();
          history.push("/");
        }
      });
  };
  useEffect(() => {
    checkToken();
    setOpen(matches);
  }, [matches]);
  return (
    <div className={classes.root} id="SideBar">
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      ></AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {open && <h2 className="SideBarLogo">JP Online</h2>}
          <IconButton onClick={handleDrawerClose}>
            <MenuIcon className="breadcrum" />
          </IconButton>
        </div>
        <Divider />
        <List className="h-100">
          {SideBarTabs.map((item, index) =>
            item.priority > role?.id && (role?.id != 1 || index != 5) ? (
              <Tooltip
                title={item.title}
                placement="right"
                arrow
                disableHoverListener={open}
              >
                <ListItem className="nav-btn" button key={index}>
                  <Link
                    className="d-flex flex-grow-1 iconContainer"
                    activeClassname={classes.active}
                    to={item.path}
                  >
                    <ListItemIcon>{item.icons} </ListItemIcon>
                    {open && <ListItemText primary={item.title} />}
                  </Link>
                </ListItem>
              </Tooltip>
            ) : null
          )}
          <button onClick={() => handleLogOut()}>
            {open ? (
              <h2 className="signOutBtn nav-btn">Log Out</h2>
            ) : (
              <Tooltip
                title="LogOut"
                placement="right"
                arrow
                disableHoverListener={open}
              >
                <ExitToAppIcon className="signOutBtn nav-btn" />
              </Tooltip>
            )}
          </button>
        </List>
      </Drawer>
      {/* <main className={useStyles.content}>
        <div className={useStyles.toolbar} /> */}
      {/* <Dashboard/> */}
      {/* </main> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
  point: state.auth.point,
});
export default connect(mapStateToProps, { logout })(withRouter(MUIDrawer));
