import { SET_LIMIT, LOGOUT, CLEAR_LIMIT } from "../actions/type";

var initailState=[];

const limit =( state = initailState , action) =>{
    switch (action.type) {
        case SET_LIMIT:
            return [
                ...state,
                action.payload,
            ];
        case CLEAR_LIMIT:
            return initailState;
        case LOGOUT:
            return initailState;
        default:
            return state;
    }
};

export default limit;