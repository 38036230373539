import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { goBack } from 'react-router-redux';
import { withRouter } from 'react-router-dom';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

class BackButton extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Button color="primary" onClick={this.props.history.goBack}><ArrowBackIcon /></Button>;
    }
}

export default withRouter(BackButton);