import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link, useHistory } from "react-router-dom";

import MUIDataTable from "mui-datatables";

import EditIcon from "@material-ui/icons/Edit";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LockIcon from '@material-ui/icons/Lock';
import { Tooltip } from "@material-ui/core";

import SweetAlert from "react-bootstrap-sweetalert";

import axios from "axios";

//redux part
import { connect } from "react-redux";
import { setSuper } from "../../actions/superAction";

const SuperDistributer = ({ server, history, access_token, superd, setSuper, role }) => {

  const classes = useStyles();
  //const history = useHistory();
  const [temp, setTemp] = useState();
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (role?.id !== 1) {
      if (role?.id === 2) {
        history.push("/Distributer")
      }
      if (role?.id === 3) {
        history.push("/Retailer");
      }
      if (role?.id === 4) {
        history.push("/Games");
      }
    }
  }, [])

  const hideAlert = () => {
    setAlert(null);
  };

  const alertcall = (errormessage) => {
    console.log("run on error");
    console.log(errormessage);
    setAlert(
      <SweetAlert
        title="ERROR !"
        onConfirm={hideAlert}
      >
        {errormessage}
      </SweetAlert>
    )
  }

  const deleteCall = (ids) => {
    console.log("alert calling");
    console.log(ids);
    setAlert(
      <SweetAlert
        warning
        title="Are you Sure ?"
        onConfirm={() => deleteRecord(ids)}
        onCancel={hideAlert}
        confirmBtnBsStyle="danger"
        cancelBtnStyle={{ color: "red" }}
        confirmBtnText="Delete!"
        cancelBtnText="Cancel"
        showCancel
      >
        This will Delete the Data.
      </SweetAlert>
    );
  };

  const blockCall = (ids) => {
    console.log("alert calling");
    setAlert(
      <SweetAlert
        warning
        title="Are you Sure ?"
        onConfirm={() => blockrecord(ids)}
        onCancel={hideAlert}
        confirmBtnBsStyle="danger"
        cancelBtnStyle={{ color: "red" }}
        confirmBtnText="Block!"
        cancelBtnText="Cancel"
        showCancel
      >
        This will Block the User.
      </SweetAlert>
    );
  };

  const unblockCall = (ids) => {
    console.log("alert calling");
    setAlert(
      <SweetAlert
        warning
        title="Are you Sure ?"
        onConfirm={() => unblockrecord(ids)}
        onCancel={hideAlert}
        //confirmBtnBsStyle="danger"
        //cancelBtnStyle={{color:"red"}}
        confirmBtnText="UnBlock!"
        cancelBtnText="Cancel"
        showCancel
      >
        This will UnBlock the User.
      </SweetAlert>
    );
  };

  const deleted = () => {
    console.log("deleted call");
    setAlert(
      <SweetAlert success title="Deleted" onConfirm={hideAlert}>
        Data has been Deleted
      </SweetAlert>
    );
  };

  const blocked = () => {
    console.log("blocked call");
    setAlert(
      <SweetAlert success title="Block" onConfirm={hideAlert}>
        This User is Blocked
      </SweetAlert>
    );
  };

  const unblocked = () => {
    console.log("unblocked call");
    setAlert(
      <SweetAlert success title="UnBlock" onConfirm={hideAlert}>
        This User is Unblock
      </SweetAlert>
    );
  };

  const blockrecord = (ids) => {
    console.log("block called");
    console.log(ids);

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    const parameter = {
      _method: "post",
    };

    axios
      .post(server + `api/super_distributor/${ids}`, parameter, config)
      .then((response) => {
        console.log("block done");
        hideAlert();
        superfetching();
        blocked();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const unblockrecord = (ids) => {
    console.log("unblock called");
    console.log(ids);

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    const parameter = {
      _method: "put",
    };

    axios
      .post(server + `api/super_distributor/${ids}`, parameter, config)
      .then((response) => {
        console.log("block done");
        hideAlert();
        superfetching();
        unblocked();
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response.data.errors[0]);
        const errormessage = error.response.data.errors[0];
        //console.log(errormessage);
        alertcall(errormessage);
      });
  };

  const deleteRecord = (ids) => {
    console.log("delete Called");
    console.log(ids);
    const parameter = {
      _method: "delete",
    };
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post(server + `api/super_distributor/${ids}`, parameter, config)
      .then((response) => {
        console.log("deleted");
        hideAlert();
        superfetching();
        deleted();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const superfetching = () => {
    console.log("use effect running");

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get(server + "api/super_distributor", config)
      .then((response) => {
        console.log("get data");
        // console.log(response.data.map((object)=> {
        //  return [object.id , object.name , object.username]
        //  console.log(...object);
        // }));
        console.log(response.data);
        setSuper(response.data); //setActivities is redux object.
        console.log(superd);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    superfetching();
  }, []);

  useEffect(() => {
    setTemp(superd.map((superd) => ({ id: superd.id, name: superd.name, username: superd.username, blocked_by: superd.blocked_by, supervisor: superd.supervisor[0].username, points: Math.round(superd.points) })))
  }, [superd])
  console.log(temp);


  // useEffect(()=>{
  //   const salman = superd.map((superd)=>({ id:superd.id , username:superd.username , amount:superd.supervisor[0].pivot.amount}))
  // },[])

  // useEffect(()=>{
  //   let newArray = activities.map(function(activity, index) {
  //     return Object.values(activities[index]);
  //   })
  //   console.log(newArray);

  // },[activities])

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <div
                onClick={() =>
                  history.push("/AccountSummary", {
                    id: tableMeta.rowData[0],
                    username: tableMeta.rowData[2],
                    name: value,
                  })
                }
                className={classes.hover}
              >
                {value}
              </div>
            </>
          );
        },
      },
      // options: {
      //   filter: true,
      //   sort: false,
      // },
    },
    {
      name: "username",
      label: "Username",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <div
                onClick={() =>
                  history.push("/AccountSummary", {
                    id: tableMeta.rowData[0],
                    username: value,
                    name: tableMeta.rowData[1],
                  })
                }
                className={classes.hover}
              >
                {value}
              </div>
            </>
          );
        },
      },
      // options: {
      //   filter: true,
      //   sort: false,
      // },
    },
    {
      name: "blocked_by",
      label: "Block by ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "supervisor",
      label: "Supervisor",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "points",
      label: "Total points",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        customBodyRender: (ids, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Edit" placement="top" arrow>
                <IconButton
                  onClick={() => history.push("/SuperDistributer/Edit", superd.filter((superd) => { return superd.id === ids }))}
                  aria-label="Edit"
                  style={{
                    backgroundColor: "#00ACC1",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Edit"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Account" placement="top" arrow>
                <IconButton
                 onClick={() =>
                  history.push("/SuperDistributer/Account", {
                    id:ids,
                    username: tableMeta.rowData[2],
                    name: tableMeta.rowData[1],
                  })
                }
                  aria-label="account"
                  style={{
                    backgroundColor: "#9966cc",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Account"
                >
                  <AccountBalanceIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Point Transfer" placement="top" arrow>
                <IconButton
                  onClick={() => history.push("/SuperDistributer/Point", superd.filter((superd) => { return superd.id === ids }))}
                  aria-label="point"
                  style={{
                    backgroundColor: "#FF1476",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Point"
                >
                  <AttachMoneyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Change Password" placement="top" arrow>
                <IconButton
                  onClick={() => history.push("/Password", superd.filter((superd) => { return superd.id === ids }))}
                  aria-label="Change Password"
                  style={{
                    backgroundColor: "#D83A56",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Edit"
                >
                  <LockIcon />
                </IconButton>
              </Tooltip>
              {tableMeta.rowData[3] ?
                (
                  <Tooltip title="Unblock" placement="top" arrow>
                    <IconButton
                      onClick={() => unblockCall(ids)}
                      aria-label="Disable"
                      style={{
                        backgroundColor: "#90ee90",
                        padding: 5,
                        marginRight: 5,
                        color: "#ffffff",
                      }}
                      className="Disable"
                    >
                      <NotInterestedIcon />
                    </IconButton>
                  </Tooltip>
                )
                :
                (
                  <Tooltip title="Block" placement="top" arrow>
                    <IconButton
                      onClick={() => blockCall(ids)}
                      aria-label="Disable"
                      style={{
                        backgroundColor: "#F44336",
                        padding: 5,
                        marginRight: 5,
                        color: "#ffffff",
                      }}
                      className="Disable"
                    >
                      <NotInterestedIcon />
                    </IconButton>
                  </Tooltip>
                )
              }

              <Tooltip title="Delete" placement="top" arrow>
                <IconButton
                  onClick={() => deleteCall(ids)}
                  aria-label="delete"
                  style={{
                    backgroundColor: "#FF9800",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>

            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: false,
    print: false,
    download: false,
  };

  return (
    <div className="dashboardMainDivContainer">
      {alert}
      <div className="card m-3 mt-5">
        <div className='titles'>
          Super Distributor
        </div>
        <Link className="BtnLinkDist" to="/SuperDistributer/Add">
          <Button
            variant="contained"
            className="SuperDistAddBtn"
            startIcon={<AddCircleIcon />}
          >
            Add Super Distributor
          </Button>
        </Link>

        {/* table Start here */}

        <div style={{ marginTop: "2%" }}>
          <MUIDataTable
            title={"Super Distributor Table"}
            data={temp}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};
const useStyles = makeStyles({

  hover: {
    cursor: "pointer",
    color: "blue"
  },
  bal: {
    // width: "20%",
    // margin: "20px 20px 0px 20px",
  },
  paper: {
    minHeight: "100px",
    // width:"100%"
    margin: "20px 5px 0px 5px",
  },
  text: {
    padding: "1em 0em 1em 1em",
    color: "grey",
    fontWeight: "70",
    textTransform: "uppercase",
  },
  amt: {
    padding: "0em 0em 1em 1em",
  }
});
const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  superd: state.superd,
  role: state.auth.role[0],
});

export default connect(mapStateToProps, { setSuper })(SuperDistributer);
