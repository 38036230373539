import { SET_RETAILER, LOGOUT, CLEAR_RETAILER } from "../actions/type";

var initailState=[];

const retailer =( state = initailState , action) =>{
    switch (action.type) {
        case SET_RETAILER:
            return [
                ...state,
                ...action.payload,
            ];
        case CLEAR_RETAILER:
            return initailState;
        case LOGOUT:
            return initailState;
        default:
            return state;
    }
};

export default retailer;