import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { connect } from "react-redux";
import { setDistributor } from "../../../actions/distributorAction";
import Backbutton from "../Backbutton";

let schema = yup.object({
  name: yup.string().required("Name is Required"),
  username: yup.string().required("Username is Required"),
  commission: yup.string().required("Commission is Required"),
  dist: yup.string().required("Distributor is Required"),
  //amounttrans:yup.string().required("Amount is required"),
  password: yup
    .string()
    .required("Password is Required")
    .min(5, ({ min }) => `Password must be at least ${min} characters`),
});

function RetailerEdit({
  server,
  history,
  access_token,
  location,
  distributor,
  setDistributor,
}) {
  const [selectData, setselectData] = React.useState("");
  console.log(location.state[0].supervisor[0].pivot.supervisor);
  console.log(location.state[0]);

  useEffect(() => {
    setselectData(location.state[0].supervisor[0].pivot.supervisor);
  }, []);

  const selectdatas = distributor.map((select) => ({
    value: select.id,
    label: select.name,
  }));

  const filterdata = distributor
    .filter(
      (distributor) =>
        distributor.id === location?.state[0].supervisor[0].pivot.supervisor
    )
    .map((select) => ({ value: select.id, label: select.username }));
  //console.log(filterdata);

  const handleChanges = (event) => {
    setselectData(event.target.value);
  };

  const distfetching = () => {
    console.log("use effect running");

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get(server + "api/distributor", config)
      .then((response) => {
        console.log("get data");
        // console.log(response.data.map((object)=> {
        //  return [object.id , object.name , object.username]
        //  console.log(...object);
        // }));
        console.log(response.data);
        setDistributor(response.data); //setActivities is redux object.
        console.log(distributor);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    distfetching();
  }, []);

  const editValue = (values, resetForm) => {
    const data = {
      name: values.name,
      //password:values.password,
      username: values.username,
      commission: values.commission,
      //amount:values.amounttrans,
      distributor: values.dist,
      _method: "put",
    };
    console.log(data);

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post(
        server + `api/register/retailor/${location.state[0].id}`,
        data,
        config
      )
      .then((response) => {
        console.log(response);
        console.log("submitted");
        resetForm();
        history.push("/Retailer");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="grayBackgroundBox">
      <div className="card container">
        <div>
          <Backbutton />
        </div>
        <Formik
          initialValues={{
            username: location.state[0].username,
            name: location.state[0].name,
            commission: location.state[0].supervisor[0].pivot.commission,
            password: "*********",
            dist: location.state[0].supervisor[0].pivot.supervisor,
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            console.log(values);
            editValue(values, resetForm);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <form onSubmit={handleSubmit} className="container">
              <div className="title">Edit RETAILER</div>
              <div>
                <div className="row">
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Username"
                      name="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    {errors.username && touched.username && (
                      <p style={{ color: "red" }}>{errors.username}</p>
                    )}
                  </div>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <p style={{ color: "red" }}>{errors.name}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Commission"
                      name="commission"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.commission}
                    />
                    {errors.commission && touched.commission && (
                      <p style={{ color: "red" }}>{errors.commission}</p>
                    )}
                  </div>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      select
                      label="Distributor"
                      name="dist"
                      onChange={(event) => {
                        handleChange(event);
                        handleChanges(event);
                      }}
                      onBlur={handleBlur}
                      value={selectData}
                    >
                      {filterdata.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {errors.dist && touched.dist && (
                      <p style={{ color: "red" }}>{errors.dist}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center mt-5">
                <Button
                  className="formSubmit"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disableElevation
                  disabled={!isValid}
                >
                  Edit Retailer
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  distributor: state.distributor,
});

export default connect(mapStateToProps, { setDistributor })(RetailerEdit);
