import {CLEAR_PERCENTAGE , SET_PERCENTAGE } from "./type";

export const setPercentage = (data) => async (dispatch, getState) =>{
    dispatch({
        type:CLEAR_PERCENTAGE,
        payload:data,
    });
    dispatch({
        type:SET_PERCENTAGE,
        payload:data,
    })
}
