import { SET_COUNT, CLEAR_COUNT,SET_USER_SUMMARY,CLEAR_USER_SUMMARY, SET_CURRENT_USER_SUMMARY, CLEAR_CURRENT_USER_SUMMARY } from "./type";

export const setDashboard = (data) => async (dispatch) => {
  dispatch({
    type: CLEAR_COUNT,
    payload: data,
  });
  dispatch({
    type: SET_COUNT,
    payload: data,
  });
};

export const setUserSummary = (data) => async (dispatch) => {
  dispatch({
    type:CLEAR_USER_SUMMARY,
    payload:data,
  })
  dispatch({
    type:SET_USER_SUMMARY,
    payload:data,
  })
}

export const setCurrentUserSummary = (data) => async (dispatch) => {
  dispatch({
    type:CLEAR_CURRENT_USER_SUMMARY,
    payload:data,
  });
  dispatch({
    type:SET_CURRENT_USER_SUMMARY,
    payload:data,
  })
}