//Auth
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const VERIFY_OTP = "VERIFY_OTP";
export const FORGET_PASSWORD = "FORGET_PASSWORD";

export const SET_ACTIVITIES = "SET_ACTIVITIES";
export const CLEAR_ACTIVITIES = "CLEAR_ACTIVITIES";

export const SET_DISTRIBUTOR = "SET_DISTRIBUTOR";
export const CLEAR_DISTRIBUTOR = "CLEAR_DISTRIBUTOR";

export const SET_RETAILER = "SET_RETAILER";
export const CLEAR_RETAILER = "CLEAR_RETAILER";

export const SET_RESULT = "SET_RESULT";
export const CLEAR_RESULT = "CLEAR_RESULT";

export const SET_SELECTED = "SET_SELECTED";
export const CLEAR_SELECTED = "CLEAR_SELECTED";

export const SET_SUPER = "SET_SUPER";
export const CLEAR_SUPER = "CLEAR_SUPER";

export const SET_POINT = "SET_POINT";
export const CLEAR_POINT = "CLEAR_POINT";

export const SET_ACCOUNT = "SET_ACCOUNT";
export const CLEAR_ACCOUNT = "CLEAR_ACCOUNT";

export const SET_PERCENTAGE = "SET_PERCENTAGE";
export const CLEAR_PERCENTAGE = "CLEAR_PERCENTAGE";

export const SET_LIMIT = "SET_LIMIT";
export const CLEAR_LIMIT = "CLEAR_LIMIT";

export const SET_SUMMARY = "SET_SUMMARY";
export const CLEAR_SUMMARY = "CLEAR_SUMMARY";

export const SET_COUNT = "SET_COUNT";
export const CLEAR_COUNT = "CLEAR_COUNT";

export const SET_ONLINE = "SET_ONLINE";
export const CLEAR_ONLINE = "CLEAR_ONLINE";

export const SET_USER_SUMMARY = "SET_USER_SUMMARY";
export const CLEAR_USER_SUMMARY = "CLEAR_USER_SUMMARY";

export const SET_CURRENT_USER_SUMMARY = "SET_CURRENT_USER_SUMMARY";
export const CLEAR_CURRENT_USER_SUMMARY = "CLEAR_CURRENT_USER_SUMMARY";
 