import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Box, Grid } from "@material-ui/core";
//accordion
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import Backbutton from "./Backbutton"
import { setAccount } from "../../actions/accountAction";
import { setSummary } from "../../actions/summaryAction";
import { setUserSummary } from "../../actions/dashboardAction";


const options = {
  filterType: "dropdown",
  selectableRows: false,
  print: false,
  download: false,
};

const Account = ({
  location,
  server,
  access_token,
  account,
  setAccount,
  setSummary,
  summary,
  history,
}) => {
  const classes = useStyles();
  const [ModalData, setModalData] = useState([]);
  const [Ticket, setTicket] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const id = location.state.id;

  const columns = [
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "comment",
      label: "Comment",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "id",
      label: "Transaction no.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (id) => {
          let temp = account.filter((item) => {
            return item.id === id;
          });
          if (temp[0].comment === "Transaction" || temp[0].comment === "Claim") {
            return (
              <span
                onClick={() => {
                  //console.log(temp[0].transactions.ticket);
                  setModalData(temp[0].transactions.ticket);
                  handleOpen(true);
                }}
                className={classes.hover}
              >
                {temp[0].transactions.transaction_no}
              </span>
            );
          } else {
            return "N/A";
          }
        },
      },
    },
    {
      name: "id",
      label: "Draw Time",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (id) => {
          let temp = account.filter((item) => {
            return item.id === id;
          });
          if (temp[0].comment === "Transaction" || temp[0].comment === "Claim") {
            return (
              <span >
                {temp[0].transactions.game_time}
              </span>
            );
          } else {
            return "N/A";
          }
        },
      },
    },
    {
      name: "created_at",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) =>
          moment(new Date(value)).format("Do MMMM YYYY"),
      },
    },

    {
      name: "created_at",
      label: "Timestamp",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) =>
          moment(new Date(value)).format("Do MMMM YYYY h:mm"),
      },
    },
  ];

  const getSummary = (id) => {
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    const parameter = {
      _method: "post",
    };
    axios
      .post(server + `api/account/summary/${id}`, parameter, config)
      .then((response) => {
        //console.log(response.data);
        setSummary(response.data);
        // setAccount(response.data);
        // //console.log(account);
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  const useraccount = (id) => {
    //console.log("running");
    //console.log(id);

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    const parameter = {
      _method: "post",
    };

    axios
      .post(server + `api/account/${id}`, parameter, config)
      .then((response) => {
        //console.log(response.data);
        setAccount(response.data);
        //console.log(account);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    useraccount(id);
    getSummary(id);
  }, []);

  useEffect(() => {
    let alphabet = Array(26)
      .fill()
      .map((_, i) => String.fromCharCode("A".charCodeAt(0) + i));

    let tickets = [];

    ModalData.forEach((data) => {
      let firsts = data.first_digit.split(",");
      let seconds = data.second_digit.split(",");
      let last2s = data.last_2_digit.split(",");
      let quants = data.quantity.split(",");
      firsts.forEach((first) => {
        seconds.forEach((second) => {
          last2s.forEach((last, index) => {
            let ticket = {};
            let temp = alphabet[second] + first + second + last;
            ticket.number = temp;
            ticket.quantity = quants[index];
            ticket.first = first;
            ticket.second = second;
            tickets = [...tickets, ticket];
          });
        });
      });
    });
    setTicket(tickets);
  }, [ModalData]);

  return (
    <div className="dashboardMainDivContainer">
      <div className="card m-3 mt-5">
        <div>
          <Backbutton />
        </div>
        <div className="titles">Account</div>
        <div className={classes.summary}>
          <p className={classes.space}>
            Credited:
            {(parseInt(summary.credits * 100) / 100)?.toFixed(2) ?? "N/A"}{" "}
            Debited:
            {(parseInt(summary.debits * 100) / 100)?.toFixed(2) ?? "N/A"} Total:
            {(parseInt(summary.total * 100) / 100)?.toFixed(2) ?? "N/A"}
          </p>
        </div>
        {/* table Start here */}

        <div style={{ marginTop: "2%" }}>
          {/* <AccordionDetails>
                <div className={classes.col}>
                  <MUIDataTable
                    title={
                      location.state.username +
                      " " +
                      location.state.name +
                      "'s Account Summary"
                    }
                    data={account}
                    columns={columns}
                    options={options}
                  />
                </div>
              </AccordionDetails> */}
          <MUIDataTable
            // title={"Account Table"}
            title={
              location.state.username +
              " " +
              location.state.name +
              "'s Account"
            }
            data={account.length > 0 ? account : []}
            columns={columns}
            options={options}
          />
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modal}>
          <Grid container>
            {Ticket.map((ticket) => {
              return (
                <Grid item xs={3}>
                  {`${ticket.number} (${ticket.quantity})`}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  account: state.account,
  summary: state.summary,
  users_summary: state.userSummary,
});

const useStyles = makeStyles({
  summary: {
    fontWeight: "bold",
  },
  space: {
    wordSpacing: "30px",
    marginTop: "10px"
  },
  hover: {
    cursor: "pointer",
    color: "blue",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#fff",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 10,
  },
});
export default connect(mapStateToProps, { setAccount, setSummary })(Account);
