import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import axios from "axios";
import moment from "moment";
//redux part
import { connect } from "react-redux";
import { setAccount } from "../../actions/accountAction";
import { setSummary } from "../../actions/summaryAction";
import { set } from "lodash";

const MyAccount = ({
  server,
  history,
  access_token,
  setAccount,
  role,
  accounts,
  setSummary,
  summary,
  user_id,
}) => {
  const classes = useStyles();

  const fetching = () => {
    console.log("use effect running");

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get(server + "api/accounts", config)
      .then((response) => {
        console.log("get data");
        console.log(response.data);
        setAccount(response.data); //setAccount is redux object.
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSummary = () => {
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    const parameter = {
      _method: "post",
    };
    axios
      .post(server + `api/account/summary/${user_id}`, parameter, config)
      .then((response) => {
        console.log(response.data);
        setSummary(response.data);
        // setAccount(response.data);
        // console.log(account);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetching();
    getSummary();
  }, []);

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "comment",
      label: "Comment",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "created_at",
      label: "Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) =>
          moment(new Date(value)).format("Do MMMM YYYY h:mm"),
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: false,
    print: false,
    download: false,
  };

  return (
    <div className="dashboardMainDivContainer">
      <div className="card m-3 mt-5">
        <div className="titles">My Account</div>
        {role.id != 1 ? (
          <div className={classes.summary}>
            Summary
            <br />
            <p className={classes.space}>
              Credited:
              {(parseInt(summary.credits * 100) / 100)?.toFixed(2) ??
                "N/A"}{" "}
              Debited:
              {(parseInt(summary.debits * 100) / 100)?.toFixed(2) ?? "N/A"}{" "}
              Total:{(parseInt(summary.total * 100) / 100)?.toFixed(2) ?? "N/A"}
            </p>
          </div>
        ) : null}
        {/* table Start here */}
        {role.id != 1 ? (
          <MUIDataTable
            title={"My Account Details"}
            data={accounts}
            columns={columns}
            options={options}
          />
        ) : null}
      </div>
    </div>
  );
};
const useStyles = makeStyles({
  summary: {
    fontWeight: "bold",
  },
  space: {
    wordSpacing: "30px",
  },
});

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  accounts: state.account,
  role: state.auth.role[0],
  summary: state.summary,
  user_id: state.auth.role[0].pivot.user_id,
});
export default connect(mapStateToProps, { setAccount, setSummary })(MyAccount);
