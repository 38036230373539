import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { Formik } from 'formik';
import Backbutton from "../Backbutton"
import * as yup from 'yup';
import axios from "axios";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
let schema = yup.object({
  name: yup.string().required("Name is Required"),
  username: yup.number().positive().integer().required("Username is Required").typeError('Username Must be of Number'),
  commission: yup.number().positive().integer().required("Commission is Required").typeError('Commission Must be of Number'),
  password: yup.string().required("Password is Required")
    .min(5, ({ min }) => `Password must be at least ${min} characters`)
});
function SuperDistributerAdd({ server, history, access_token, role }) {
  const [errortext, seterrortext] = useState('');
  const [alert, setAlert] = useState(null);
  useEffect(() => {
    if (role?.id !== 1) {
      if (role?.id === 2) {
        history.push("/Distributer")
      }
      if (role?.id === 3) {
        history.push("/Retailer");
      }
      if (role?.id === 4) {
        history.push("/Games");
      }
    }
  }, [])
  const hideAlert = () => {
    setAlert(null);
  };
  const added = () => {
    console.log("added call");
    setAlert(
      <SweetAlert success title="Added" onConfirm={hideAlert}>
        User has been created
      </SweetAlert>
    );
  };
  const addValue = (values, resetForm) => {
    const data = {
      name: values.name,
      username: values.username,
      password: values.password,
      commission: values.commission
    }
    console.log(data);

    const config = {
      headers: { Authorization: `Bearer ${access_token}` }
    };
    axios
      .post(server + "api/register/super_distributor", data, config)
      .then(response => {
        console.log(response);
        console.log("submitted");
        added();
        resetForm();
        seterrortext('');
      })
      .catch(error => {
        console.log(error.response.data.errors);
        const errormessage = error.response.data.errors.username[0];
        console.log(errormessage);
        seterrortext(errormessage);
        // let errorarray = []
        // Object.keys(errormessage).forEach(function(key){
        //   errormessage[key].forEach(value => {
        //     errorarray = [...errorarray , value];

        //   })
        // })
        // console.log(errorarray[0]);
      })
  };
  console.log(errortext);
  return (
    <div className='grayBackgroundBox'>
      {alert}
      <div className='card container'>
        <div>
          <Backbutton />
        </div>
        <Formik
          initialValues={{ username: '', name: '', commission: '', password: '' }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            addValue(values, resetForm);
          }}
        >
          {({ values, handleBlur, handleChange, handleSubmit, errors, touched, isSubmitting, isValid }) => (
            <form className='container' onSubmit={handleSubmit}>
              <div className='title'>
                Add New Super Distributor
              </div>
              <div >
                <div className='row'>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField className='inputField' id="standard-basic"
                      label="Username"
                      name="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username} />
                    {(errors.username) &&
                      <p style={{ color: "red" }}>{errors.username}</p>}
                    {errortext && (
                      <p style={{ color: "red" }}>{errortext}</p>
                    )}
                  </div>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField className='inputField' id="standard-basic"
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name} />
                    {(errors.name && touched.name) &&
                      <p style={{ color: "red" }}>{errors.name}</p>}
                  </div>
                </div>
                <div className='row'>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField className='inputField' id="standard-basic"
                      label="Commission"
                      name='commission'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.commission} />
                    {(errors.commission && touched.commission) &&
                      <p style={{ color: "red" }}>{errors.commission}</p>}
                  </div>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField className='inputField' id="standard-password-input" type="password"
                      label="Password"
                      autoComplete="current-password"
                      name='password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password} />
                    {(errors.password && touched.password) &&
                      <p style={{ color: "red" }}>{errors.password}</p>}
                  </div>
                </div>
              </div>
              <div className='text-center mt-5'>
                <Button className='formSubmit' variant="contained" type='submit'
                  color="primary"
                  disabled={!isValid}
                  disableElevation>
                  Save Super Distributor
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
});


export default connect(mapStateToProps,
  {})(SuperDistributerAdd);
