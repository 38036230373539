import { SET_SUPER, LOGOUT, CLEAR_SUPER } from "../actions/type";

var initailState=[];

const superd =( state = initailState , action) =>{
    switch (action.type) {
        case SET_SUPER:
            return [
                ...state,
                ...action.payload,
            ];
        case CLEAR_SUPER:
            return initailState;
        case LOGOUT:
            return initailState;
        default:
            return state;
    }
};

export default superd;