import authReducer from "./auth";
import activitiesReducer from "./activities";
import distributorReducer from './distributor';
import retailerReducer from './retailer';
import superdReducer from './super';
import pointsReducer from './points';
import accountReducer from './account';
import percentageReducer from './percentage';
import limitReducer from './limit';
import summaryReducer from './summary';
import dashboardReducer from './dashboard';
import useronlineReducer from './useronline';
import resultReducer from './result';
import savedReducer from "./saved";
import  userSummaryReducer  from "./userSummary";
import currentUserReducer from "./currentUser";
import { combineReducers } from "redux";

const allReducer = combineReducers({
  auth: authReducer,
  activities:activitiesReducer,
  distributor:distributorReducer,
  retailer:retailerReducer,
  superd:superdReducer,
  points:pointsReducer,
  account:accountReducer,
  percentage:percentageReducer,
  limit:limitReducer,
  summary:summaryReducer,
  dashboard:dashboardReducer,
  online:useronlineReducer,
  result:resultReducer,
  selected:savedReducer,
  userSummary:userSummaryReducer,
  currentUser:currentUserReducer,
});

export default allReducer;
