import {CLEAR_RETAILER , SET_RETAILER } from "./type";

export const setRetailer = (data) => async (dispatch, getState) =>{
    dispatch({
        type:CLEAR_RETAILER,
        payload:data,
    });
    dispatch({
        type:SET_RETAILER,
        payload:data,
    })
}
