import React, { useState, useEffect } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import Backbutton from "../views/Backbutton"
//accordion
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
//icons
import TodayIcon from "@material-ui/icons/Today";
import EventIcon from "@material-ui/icons/Event";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//redux
import axios from "axios";
import { connect } from "react-redux";
import { setAccount } from "../../actions/accountAction";
import { setUserSummary } from "../../actions/dashboardAction";

const AccountSummary = ({
  history,
  server,
  access_token,
  setAccount,
  account,
  location,
  users_summary,
  setUserSummary,
}) => {
  console.log(location);
  const classes = useStyles();
  const [fromdate, setfromdate] = useState(new Date());
  const [todate, settodate] = useState(new Date());
  const initialdate = new Date();
  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };

  const dist = users_summary.filter((value) => {
    return value.role.name == "Distributor";
  });
  const ret = users_summary.filter((value) => {
    return value.role.name == "Retailor";
  });

  console.log(location);
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const handleFromDate = (event) => {
    setfromdate(event.target.value);
  };
  const handleToDate = (event) => {
    settodate(event.target.value);
  };

  const userSummary = () => {
    const parameter = {
      _method: "post",
      user_id: location.state.id,
    };
    axios
      .post(server + `api/user_summary`, parameter, config)
      .then((response) => {
        console.log(response.data);
        setUserSummary(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const accountfetch = () => {
    // formatDate(fromdate._d);
    console.log(formatDate(fromdate));
    console.log(formatDate(todate));

    const parameter = {
      _method: "post",
      date: [formatDate(fromdate), formatDate(todate)],
      user_id: location.state.id,
    };

    axios
      .post(server + `api/account`, parameter, config)
      .then((response) => {
        setAccount(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const parameter = {
      _method: "post",
      user_id: location.state.id,
    };

    axios
      .post(server + `api/account`, parameter, config)
      .then((response) => {
        console.log(response.data);
        setAccount(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    userSummary();
  }, []);

  const columns = [
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) =>
          value == "Total"
            ? value
            : moment(new Date(value)).format("Do MMMM YYYY"),
      },
    },
    {
      name: "credits",
      label: "Sale",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
    {
      name: "debits",
      label: "Win",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
    {
      name: "commissions",
      label: "Commission",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
    {
      name: "total",
      label: "Final",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
  ];

  const user_columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <div
                onClick={() =>
                  history.push("/AccountSummary", {
                    id: tableMeta.rowData[0],
                    username: tableMeta.rowData[2],
                    name: value,
                  })
                }
                className={classes.hover}
              >
                {value}
              </div>
            </>
          );
        },
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <div
                onClick={() =>
                  history.push("/AccountSummary", {
                    id: tableMeta.rowData[0],
                    username: value,
                    name: tableMeta.rowData[1],
                  })
                }
                className={classes.hover}
              >
                {value}
              </div>
            </>
          );
        },
      },
    },
    {
      name: "total_sale",
      label: "Total Sale",
      options: {
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
    {
      name: "balance",
      label: "Balance Pending",
      options: {
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
    {
      name: "amount_transfered",
      label: "Amount Transfered",
      options: {
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
    {
      name: "user_commission",
      label: "User Commission",
      options: {
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
    {
      name: "total_commission",
      label: "Total Commission",
      options: {
        customBodyRender: (value) => (parseInt(value * 100) / 100).toFixed(2),
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: false,
    print: false,
    download: false,
    viewColumns: false,
  };

  return (
    <div className="dashboardMainDivContainer">

      <div className="card m-3 mt-5">
        <div>
          <Backbutton />
        </div>
        <div className="titles">Account Summary</div>
        <Grid container className={classes.begin}>
          <Grid container item md={6} xs={12}>
            <Grid item md={3} className={classes.icon}>
              <TodayIcon style={{ color: "#0066CC",marginTop:"-20px" }} />
            </Grid>
            <Grid item md={9} className={classes.start}>
              {/* <p style={{ color: "#0066CC" }}>Desired Start Date</p> */}
              <TextField
                id="standard-basic"
                className="inputField"
                label="Start Date"
                type="date"
                style={{
                  marginTop:"30px",
                  marginLeft:"2px"
                }}
                value={formatDate(fromdate)}
                defaultValue={formatDate(fromdate)}
                onChange={handleFromDate}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ max: formatDate(initialdate) }}
              />
            </Grid>
          </Grid>
          <Grid container item md={6} xs={12}>
            <Grid item md={2} className={classes.icon1}>
              <EventIcon style={{ color: "#0066CC",marginTop:"-20px" }} />
            </Grid>
            <Grid item md={8} className={classes.end}>
           
              <TextField
                id="standard-basic"
                className="inputField"
                label="End Date"
                type="date"
                style={{
                  marginTop:"30px"
                }}
                value={formatDate(todate)}
                defaultValue={formatDate(todate)}
                onChange={handleToDate}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ max: formatDate(initialdate) }}
              />
            </Grid>
            <Grid  item md={2} xs={12} className={classes.submit}>
              <Button
               
                variant="contained"
                onClick={() => accountfetch()}
                style={{
                  marginLeft:"-140px",
                  backgroundColor: "#3F51B5",
                  textTransform: "none",
                  color: "white",
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <div style={{ marginTop: "4%" }}>
            <Accordion defaultExpanded>
              {/* <AccordionSummary className={classes.heading}>
                {location.state.username + " " + location.state.name}'s Account
                Summary
              </AccordionSummary> */}
              <AccordionDetails>
                <div className={classes.col}>
                  <MUIDataTable
                    title={
                      location.state.username +
                      " " +
                      location.state.name +
                      "'s Account Summary"
                    }
                    data={account}
                    columns={columns}
                    options={options}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
        {dist.length != 0 ? (
          <div style={{ marginTop: "40px" }}>
            <Accordion defaultExpanded>
              <AccordionSummary
                className={classes.heading}
                expandIcon={<ExpandMoreIcon />}
              >
                Distributor
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.col}>
                  <MUIDataTable
                    title={"Today's Summary"}
                    data={dist}
                    columns={user_columns}
                    options={options}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : null}
        {ret.length != 0 ? (
          <div style={{ marginTop: "40px" }}>
            <Accordion defaultExpanded>
              <AccordionSummary
                className={classes.heading}
                expandIcon={<ExpandMoreIcon />}
              >
                Retailer
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.col}>
                  <MUIDataTable
                    title={"Today's Summary"}
                    data={ret}
                    columns={user_columns}
                    options={options}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  start: {
    color: "#F13BCD",
    marginLeft: "1%",
  },
  icon: {
    marginTop: "4em",
    // marginLeft: "40%",
    maxWidth: "20px",
  },
  icon1: {
    marginTop: "4em",
    marginLeft: "2%",
    maxWidth: "30px",
  },
  submit: {
    color: "#3F51B5",
    marginTop: "8%",
    textAlign:"center"
    // marginLeft: "3%",
  },
  col: {
    flexBasis: "100%",
  },
  hover: {
    cursor: "pointer",
    color: "blue"

  },
  heading: {
    fontWeight: "bold",
  },
  begin: {
    marginLeft: "5em"
  }
});

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
  account: state.account,
  users_summary: state.userSummary,
});
export default connect(mapStateToProps, { setAccount, setUserSummary })(
  AccountSummary
);
