import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import Backbutton from "../Backbutton"

import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

import SweetAlert from "react-bootstrap-sweetalert";

import { setSuper } from "../../../actions/superAction";

let schema = yup.object({
  name: yup.string().required("Name Is Required"),
  username: yup.number().positive().integer().required("Username is Required").typeError('Username Must be of Number'),
  commission: yup.number().positive().integer().required("Commission is Required").typeError('Commission Must be of Number'),
  superdist: yup.string().required("Super Distrubutor is Required"),
  password: yup.string().required("Password is Required")
    .min(5, ({ min }) => `Password must be at least ${min} characters`)

});


function DistributerAdd({ server, history, access_token, superd, setSuper, role }) {
  const classes = useStyles();
  const [errortext, seterrortext] = useState('');
  const [selectData, setselectData] = React.useState('');
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (role?.id !== 1) {
      if (role?.id !== 2) {
        if (role?.id === 3) {
          history.push("/Retailer");
        }
        if (role?.id === 4) {
          history.push("/Games");
        }
      }
    }
  }, [])

  const hideAlert = () => {
    setAlert(null);
  };
  const added = () => {
    console.log("added call");
    setAlert(
      <SweetAlert success title="Added" onConfirm={hideAlert}>
        User has been created
      </SweetAlert>
    );
  };

  const selectdatas =
    superd.map(select => ({ value: select.id, label: select.username }))
  // console.log(selectdatas);
  // console.log(superd);

  const filterd =
    superd.filter(item => item.id == role?.pivot?.user_id).map(select => ({ value: select.id, label: select.username }))

  //console.log(filterd);

  // const temparray =
  // superd.map(supers => ({id : supers.id , name:supers.name , create:supers.created_at , amount:supers.supervisor[0].pivot.amount}));
  // console.log(temparray);


  const handleChanges = (event) => {
    setselectData(event.target.value);
  };


  const addValue = (values, resetForm) => {
    const data = {
      name: values.name,
      username: values.username,
      password: values.password,
      super_distributor: values.superdist,
      commission: values.commission
    }
    console.log(data);

    const config = {
      headers: { Authorization: `Bearer ${access_token}` }
    };

    axios
      .post(server + "api/register/distributor", data, config)
      .then(response => {
        console.log(response);
        console.log("submitted");
        added();
        resetForm();
        seterrortext('');
      })
      .catch(error => {
        console.log(error.response);
        const errormessage = error.response.data.errors.username[0];
        console.log(errormessage);
        seterrortext(errormessage);
      })
  };

  const superfetching = () => {
    console.log("use effect running");

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get(server + "api/super_distributor", config)
      .then((response) => {
        console.log("get data");
        console.log(response.data);
        setSuper(response.data); //setActivities is redux object.
        console.log(superd);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    superfetching();
  }, []);

  return (
    <div className='grayBackgroundBox'>
      {alert}
      <div className='card container'>
        <div>
          <Backbutton />
        </div>
        <Formik
          initialValues={{ username: '', name: '', commission: '', password: '', superdist: '' }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            addValue(values, resetForm);
            console.log(values)
          }}
        >
          {({ values, handleBlur, handleChange, handleSubmit, errors, touched, isSubmitting, isValid }) => (
            <form onSubmit={handleSubmit} className='container'>
              <div className='title'>
                Add New Distributor
              </div>
              <div >
                <div className='row'>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField className='inputField' id="standard-basic"
                      label="Username"
                      name="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username} />
                    {(errors.username) &&
                      <p style={{ color: "red" }}>{errors.username}</p>}
                    {errortext && (
                      <p style={{ color: "red" }}>{errortext}</p>
                    )}
                  </div>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField className='inputField' id="standard-basic"
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name} />
                    {(errors.name && touched.name) &&
                      <p style={{ color: "red" }}>{errors.name}</p>}
                  </div>
                </div>
                <div className='row'>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField className='inputField' id="standard-basic"
                      label="Commission"
                      name='commission'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.commission} />
                    {(errors.commission && touched.commission) &&
                      <p style={{ color: "red" }}>{errors.commission}</p>}
                  </div>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField className='inputField' id="standard-password-input" type="password"
                      label="Password"
                      autoComplete="current-password"
                      name='password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password} />
                    {(errors.password && touched.password) &&
                      <p style={{ color: "red" }}>{errors.password}</p>}
                  </div>
                </div>
                <div className='row'>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    {role?.id === 1 ?
                      (
                        <TextField className='inputField' id="standard-basic"
                          select
                          label="Super Distributor"
                          name='superdist'
                          onChange={(event) => {
                            handleChange(event);
                            handleChanges(event);
                          }}
                          onBlur={handleBlur}
                          value={selectData}>
                          {selectdatas.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      ) :
                      (
                        <TextField className='inputField' id="standard-basic"
                          select
                          label="Super Distributor"
                          name="superdist"
                          onChange={(event) => {
                            handleChange(event);
                            handleChanges(event);
                          }}
                          onBlur={handleBlur}
                          value={selectData} >
                          {filterd.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )
                    }
                    {(errors.superdist && touched.superdist) &&
                      <p style={{ color: "red" }}>{errors.superdist}</p>}

                  </div>
                </div>
              </div>
              <div className='text-center mt-5'>
                <Button className='formSubmit' type='submit' variant="contained" color="primary"
                  disabled={!isValid}
                  disableElevation
                >
                  Save Distributor
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  superd: state.superd,
  role: state.auth.role[0],
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 380,
  },
  selectEmpty: {
    marginTop: theme.spacing(3),
  },
}));

export default connect(mapStateToProps,
  { setSuper })(DistributerAdd);
