import {CLEAR_SUMMARY , SET_SUMMARY } from "./type";

export const setSummary = (data) => async (dispatch, getState) =>{
    dispatch({
        type:CLEAR_SUMMARY,
        payload:data,
    });
    dispatch({
        type:SET_SUMMARY,
        payload:data,
    })
}
