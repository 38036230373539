import { SET_DISTRIBUTOR, LOGOUT, CLEAR_DISTRIBUTOR } from "../actions/type";

var initailState=[];

const distributor =( state = initailState , action) =>{
    switch (action.type) {
        case SET_DISTRIBUTOR:
            return [
                ...state,
                ...action.payload,
            ];
        case CLEAR_DISTRIBUTOR:
            return initailState;
        case LOGOUT:
            return initailState;
        default:
            return state;
    }
};

export default distributor;