import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Link } from 'react-router-dom';

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import EditIcon from "@material-ui/icons/Edit";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from "@material-ui/core";

const rowss =[
  {
    id:"1",
    username:"ansari702",
    name:"salman",
    email:"salman98101@gmail.com",
    super:"100012",
  },
  {
    id:"2",
    username:"ansari702",
    name:"salman",
    email:"salman98101@gmail.com",
    super:"100012",
  }
]

export const actions = {
  Edit:
  <Tooltip title="Edit" placement="top" arrow>
    <IconButton aria-label="Edit" style={{backgroundColor:'#00ACC1',
    padding: 5,
    color:'#ffffff'}} className='Edit'>
      <EditIcon  />
    </IconButton>
    </Tooltip>
    ,
  Delete:
  <Tooltip title="Delete" placement="top" arrow>
    <IconButton aria-label="delete" style={{backgroundColor:'#FF9800',
    padding: 5,
    color:'#ffffff'}} className='Delete'>
      <DeleteIcon  />
    </IconButton>
    </Tooltip>
    ,
  Disable:
  <Tooltip title="Disable" placement="top" arrow>
    <IconButton aria-label="Disable" style={{backgroundColor:'#F44336',
    padding: 5,
    color:'#ffffff'}} className='Disable'>
        <NotInterestedIcon />
      </IconButton >
    </Tooltip>
  ,
}

const Games = () => {
  return (
    <div className="dashboardMainDivContainer">
        <div className="card m-3 mt-5">
          <div className='titles'>
            Game
          </div>
          {/* <Link className="BtnLinkDist" to='/SuperDistributer/Add'>
          <Button
            variant="contained"
            className="SuperDistAddBtn"
            startIcon={<AddCircleIcon />}
          >
            Add Super Distributor
          </Button>
          </Link> */}

          
          {/* table Start here */}
          <div style={{marginTop:25}}>
            <TableContainer
            component={Paper}
            style={{
              maxWidth: "100%",
              position: "relative",
              height: "68vh"
            }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{width:10}}>#</StyledTableCell>
                    <StyledTableCell>Username</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Super Distributor</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowss.map((row)=>(
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
                      <StyledTableCell align="right">{row.username}</StyledTableCell>
                      <StyledTableCell align="right">{row.name}</StyledTableCell>
                      <StyledTableCell align="right">{row.email}</StyledTableCell>
                      <StyledTableCell align="right">{row.super}</StyledTableCell>
                      <StyledTableCell align="right">{actions.Edit}{''} {actions.Delete}{''} {actions.Disable}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>              
            </TableContainer>

          </div>

        </div>
      </div>
  )
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    width: 500,
    fontWeight: "bold",
    textAlign: "center",
    borderColor: "black",
    fontSize: 18
  },
  body: {
    fontSize: 16,
    textAlign: "center",
    border: "none"
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
      width: 500
    }
  }
}))(TableRow);

export default Games;
