import {CLEAR_POINT , SET_POINT } from "./type";

export const setPoints = (data) => async (dispatch, getState) =>{
    dispatch({
        type:CLEAR_POINT,
        payload:data,
    });
    dispatch({
        type:SET_POINT,
        payload:data,
    })
}