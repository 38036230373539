import { SET_PERCENTAGE, LOGOUT, CLEAR_PERCENTAGE } from "../actions/type";

var initailState=[];

const percentage =( state = initailState , action) =>{
    switch (action.type) {
        case SET_PERCENTAGE:
            return [
                ...state,
                action.payload,
            ];
        case CLEAR_PERCENTAGE:
            return initailState;
        case LOGOUT:
            return initailState;
        default:
            return state;
    }
};

export default percentage;