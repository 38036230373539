import React ,{useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Backbutton from "../Backbutton";

import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { connect } from "react-redux";

let schema = yup.object({
  name: yup.string().required("Name is Required"),
  username: yup.string().required("Username is Required"),
  commission: yup.string().required("Commission is Required"),
  password: yup
    .string()
    .required("Password is Required")
    .min(5, ({ min }) => `Password must be at least ${min} characters`),
});

function SuperDistributerEdit({ server, history, access_token , location}) {
  // console.log(location.state[0].supervisor[0].pivot.commission)

  const editValue = (values, resetForm ) => {
    const data = {
      name: values.name,
      username: values.username,
      //password: values.password,
      commission: values.commission,
      _method: "put",
    };
    console.log(data);

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post(server + `api/register/super_distributor/${location.state[0].id}`, data, config)
      .then((response) => {
        console.log(response);
        console.log("submitted");
        resetForm();
        history.push("/SuperDistributer");
        
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="grayBackgroundBox">
      <div className="card container">
      <div>
          <Backbutton />
        </div>
        <Formik
          initialValues={{
            username: location?.state[0]?.username,
            name: location?.state[0]?.name,
            commission: location?.state[0]?.supervisor[0]?.pivot.commission,
            password: "*********",
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            console.log(values);
            editValue(values, resetForm);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <form className="container" onSubmit={handleSubmit}>
              <div className="title">Edit Super Distributor</div>
              <div>
                <div className="row">
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Username"
                      name="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        readOnly: true,
                      }}
                      value={values.username}
                    />
                    {errors.username && touched.username && (
                      <p style={{ color: "red" }}>{errors.username}</p>
                    )}
                  </div>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <p style={{ color: "red" }}>{errors.name}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Commission"
                      name="commission"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.commission}
                    />
                    {errors.commission && touched.commission && (
                      <p style={{ color: "red" }}>{errors.commission}</p>
                    )}
                  </div>
                  {/* <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      disabled
                      className="inputField"
                      id="standard-password-input"
                      type="password"
                      label="Password(Can't edit password)"
                      autoComplete="current-password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {errors.password && touched.password && (
                      <p style={{ color: "red" }}>{errors.password}</p>
                    )}
                  </div> */}
                </div>
              </div>
              <div className="text-center mt-5">
                <Button
                  className="formSubmit"
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={!isValid}
                  disableElevation
                >
                  Edit Super Distributor
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
});

export default connect(mapStateToProps, {})(SuperDistributerEdit);
