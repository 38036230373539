import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Backbutton from "../Backbutton"
import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { connect } from "react-redux";
import { setSuper } from "../../../actions/superAction";



let schema = yup.object({
  name: yup.string().required("Name Is Required"),
  username: yup.string().required("Username Is Required"),
  commission: yup.string().required("Commission is Required"),
  superdist: yup.string().required("Super Distrubutor is Required"),
  password: yup.string().required("Password is Required")
    .min(5, ({ min }) => `Password must be at least ${min} characters`)

});

function DistributerEdit({ server, history, access_token, location, superd, setSuper }) {
  const [selectData, setselectData] = React.useState('');
  // console.log(location.state);
  console.log(location.state[0].supervisor[0].pivot.supervisor);

  useEffect(() => {
    setselectData(location.state[0].supervisor[0].pivot.supervisor);
  }, []);

  const superfetching = () => {
    console.log("use effect running");

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get(server + "api/super_distributor", config)
      .then((response) => {
        console.log("get data");
        console.log(response.data);
        setSuper(response.data); //setActivities is redux object.
        console.log(superd);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    superfetching();
  }, []);

  console.log(superd);
  const selectdatas =
    superd.map(select => ({ value: select.id, label: select.username }));

  const tempdata =
    superd.filter(superd => superd.id === location?.state[0].supervisor[0].pivot.supervisor).map(select => ({ value: select.id, label: select.username }))
  console.log(tempdata);

  const handleChanges = (event) => {
    setselectData(event.target.value);
  };
  const editValue = (values, resetForm) => {
    const data = {
      name: values.name,
      username: values.username,
      //password:values.password,
      super_distributor: values.superdist,
      commission: values.commission,
      _method: "put",
    }
    console.log(data);

    const config = {
      headers: { Authorization: `Bearer ${access_token}` }
    };

    axios
      .post(server + `api/register/distributor/${location.state[0].id}`, data, config)
      .then(response => {
        console.log(response);
        console.log("submitted");
        resetForm();
        history.push("/Distributer");
      })
      .catch(error => {
        console.log(error);
      })
  };
  return (
    <div className='grayBackgroundBox'>
      <div className='card container'>
        <div>
          <Backbutton />
        </div>
        <Formik
          initialValues={{
            username: location.state[0].username,
            name: location.state[0].name,
            commission: location.state[0].supervisor[0].pivot.commission,
            // password: '*********',
            superdist: location.state[0].supervisor[0].pivot.supervisor,
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            console.log(values)
            editValue(values, resetForm);
          }}
        >
          {({ values, handleBlur, handleChange, handleSubmit, errors, touched, isSubmitting, isValid }) => (
            <form onSubmit={handleSubmit} className='container'>
              <div className='title'>
                Edit New Distributor
              </div>
              <div >
                <div className='row'>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField className='inputField' id="standard-basic"
                      label="Username"
                      name="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    {(errors.username && touched.username) &&
                      <p style={{ color: "red" }}>{errors.username}</p>}
                  </div>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField className='inputField' id="standard-basic"
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name} />
                    {(errors.name && touched.name) &&
                      <p style={{ color: "red" }}>{errors.name}</p>}
                  </div>
                </div>
                <div className='row'>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField className='inputField' id="standard-basic"
                      label="Commission"
                      name='commission'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.commission} />
                    {(errors.commission && touched.commission) &&
                      <p style={{ color: "red" }}>{errors.commission}</p>}
                  </div>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField className='inputField' id="standard-basic"
                      select
                      label="Super Distributor"
                      name='superdist'
                      onChange={(event) => {
                        handleChange(event);
                        handleChanges(event);
                      }}
                      onBlur={handleBlur}
                      value={selectData}>
                      {tempdata.map((option) => (
                        <MenuItem key={option.value} value={option.value}  >
                          {option.label}

                        </MenuItem>
                      ))}
                    </TextField>
                    {(errors.superdist && touched.superdist) &&
                      <p style={{ color: "red" }}>{errors.superdist}</p>}
                  </div>
                </div>
              </div>
              <div className='text-center mt-5'>
                <Button className='formSubmit' type='submit' variant="contained" color="primary"
                  disabled={!isValid}
                  disableElevation>
                  Edit Distributor
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  superd: state.superd,
  role: state.auth.role[0],
});


export default connect(mapStateToProps,
  { setSuper })(DistributerEdit);
