import {SET_SELECTED , LOGOUT , CLEAR_SELECTED} from "../actions/type";

var initailState = {};

const savedReducer =( state = initailState , action) =>{
    switch (action.type) {
        case SET_SELECTED:
            return {
                ...state,
                ...action.payload,
            };
        case CLEAR_SELECTED:
            return initailState;
        case LOGOUT:
            return initailState;
        default:
            return state;
    }
};

export default savedReducer;