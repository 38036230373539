import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";

import { connect } from "react-redux";
import {setPercentage} from "../../actions/percentageAction";

import SweetAlert from "react-bootstrap-sweetalert";

let schema = yup.object({
  min: yup.number().integer().required("minimum is Required"),
  max: yup.number().integer().required("maximum is Required"),
});


const Settings = ({server, access_token, setPercentage,percentage}) => {
    const[min,setMin]= useState();
    const[max,setMax]= useState();
    const [alert, setAlert] = useState(null);

  const hideAlert = () => {
    setAlert(null);
  };
  const added = () => {
    console.log("added call");
    setAlert(
      <SweetAlert success title="Added" onConfirm={hideAlert}>
        Percentage Has Been Added.
      </SweetAlert>
    );
  };

  useEffect(()=>{
    getPercentage();
  },[]);

  const getPercentage = () =>
  {
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get(server + "api/percentage",config)
      .then((response) => {
        console.log(response.data);
        setPercentage(response.data);
        setMin(response.data.min);
        setMax(response.data.max);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }
  const reset = () => {
    setMax("");
    setMin("");
  }
  const handleMin = (event) =>{
      setMin(event.target.value);
  }
  const handleMax = (event) =>{
    setMax(event.target.value);
}
  const addValue =(values, resetForm) =>{
    const data={
      min:min,
      max:max,
    }
    console.log(data);

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post(server + "api/percentage/add", data, config)
      .then((response) => {
        console.log(response.data);
        console.log("submitted");
        reset();
        added();
        getPercentage();
        //setselectData('');
      })
      .catch((error) => {
        console.log(error.response);
        const message = error.response.data.errors[0];
        setAlert(
          <SweetAlert error title="Invalid Request" onConfirm={hideAlert}>
            {message}
          </SweetAlert>
        );
      });
  }
  return (
    <div className="grayBackgroundDouble">
      {alert}
      <div className="card container">
        <Formik
          initialValues={{
            min:70,
            max:90,
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            addValue(values, resetForm);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <form className="container" onSubmit={handleSubmit}>
              <div className="title">Add Percentage Range</div>
              <div>
                <div className="row">
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      id="standard-basic"
                      className="inputField"
                      label="Minimum Percentage"
                      value={min}
                      onFocus={handleBlur}
                      onChange={(event) => {
                        let input = event.target.value ;
                        if( !input || ( input[input.length-1].match('[0-9]') && input[0].match('[1-9]')) )
                        {
                            handleChange(event);
                            handleMin(event);
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {(errors.min && touched.min) &&
                <p style={{color:"red"}}>{errors.min}</p>}               
                  </div>
                  <div className="col-12 col-md-6 inputFieldContainer">
                  <TextField
                      id="standard-basic"
                      className="inputField"
                      label="Maximum Percentage"
                      value={max}
                      onFocus={handleBlur}
                      onChange={(event) => {
                        let input = event.target.value ;
                        if( !input || ( input[input.length-1].match('[0-9]') && input[0].match('[1-9]')) )
                        {
                            handleChange(event);
                            handleMax(event);
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {(errors.max && touched.max) &&
                <p style={{color:"red"}}>{errors.max}</p>}               
                  </div>
                </div>
              </div>
              <div className="text-center mt-5">
                <Button
                  className="formSubmit"
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={!isValid}
                  disableElevation
                >
                  Add Percentage
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  percentage: state.percentage,
});
export default connect(mapStateToProps, {setPercentage})(Settings);
