import React, { Component } from "react";
import { createBrowserHistory } from "history";
import Dashboard from "./components/views/dashboard/Dashboard";
import "./assets/scss/App.scss";

import SuperDistributer from "./components/views/SuperDistributer";
import Distributer from "./components/views/Distributer";
import Retailer from "./components/views/Retailer";
import Games from "./components/views/Games";
import PointTransfer from "./components/views/PointTransfer";
import PointTransfers from "./components/views/PointTransfers";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import SuperDistributerAdd from "./components/views/SuperDistributer/SuperDistributerAdd";
import SuperDistributerEdit from "./components/views/SuperDistributer/SuperDistributerEdit";
import DistributerAdd from "./components/views/Distributer/DistributerAdd";
import DistributerEdit from "./components/views/Distributer/DistributerEdit";
import RetailerAdd from "./components/views/Retailer/RetailerAdd";
import RetailerEdit from "./components/views/Retailer/RetailerEdit";
import Login from "./components/login/Login";
import PrivateRoute from "./privateRoute";
import Account from "./components/views/Account";
import AdminTool from "./components/views/AdminTool";
import Settings from "./components/views/Settings";
import Result from "./components/Result";
import Limit from "./components/views/Limit";
import ChangePassword from "./components/views/ChangePassword";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import factory from "./store";
import MyAccount from "./components/views/MyAccount";
import AccountSummary from "./components/views/AccountSummary";

const { store, persistor } = factory();

const hist = createBrowserHistory();

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
});

export default function App() {
  const classes = useStyles();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={hist}>
          <Switch>
            {/* SideBar Routes */}
            <Route path="/" component={Login} exact={true} />
            <Route path="/Result" exact component={Result} />
           
            <PrivateRoute
              path="/Dashboard"
              component={Dashboard}
              exact={true}
            />
            <PrivateRoute
              path="/SuperDistributer"
              exact
              component={SuperDistributer}
            />
            <PrivateRoute path="/Distributer" exact component={Distributer} />
            <PrivateRoute path="/Retailer" exact component={Retailer} />
            <PrivateRoute path="/Games" exact component={Games} />
            <PrivateRoute path="/PointTransfer" exact component={PointTransfer} />
            <PrivateRoute path="/AdminTool" exact component={AdminTool} />
           
            <PrivateRoute path="/Settings" exact component={Settings} />
            <PrivateRoute path="/ReprintLimit" exact component={Limit} />
            <PrivateRoute path="/Password" exact component={ChangePassword} />
            <PrivateRoute path="/MyAccount" exact component={MyAccount} />
            <PrivateRoute path="/AccountSummary" exact component={AccountSummary} />
            {/* <PrivateRoute path="/Point" exact component={PointTransfers} /> */}
            {/* <PrivateRoute path="/Account" exact component={Account} /> */}


            {/* Addition Routes */}
            <PrivateRoute
              path="/SuperDistributer/Add"
              exact
              component={SuperDistributerAdd}
            />
            <PrivateRoute
              path="/SuperDistributer/Edit"
              exact
              component={SuperDistributerEdit}
            />
            <PrivateRoute
              path="/SuperDistributer/Account"
              exact
              component={Account}
            />
            <PrivateRoute
              path="/SuperDistributer/Point"
              exact
              component={PointTransfers}
            />
            <PrivateRoute
              path="/Distributer/Add"
              exact
              component={DistributerAdd}
            />
            <PrivateRoute
              path="/Distributer/Edit"
              exact
              component={DistributerEdit}
            />
            <PrivateRoute
              path="/Distributer/Account"
              exact
              component={Account}
            />
            <PrivateRoute
              path="/Distributer/Point"
              exact
              component={PointTransfers}
            />
            <PrivateRoute 
              path="/Retailer/Add" 
              exact 
              component={RetailerAdd} 
            />
            <PrivateRoute 
              path="/Retailer/Edit" 
              exact 
              component={RetailerEdit} 
            />
            <PrivateRoute
              path="/Retailer/Account"
              exact
              component={Account}
            />
            <PrivateRoute
              path="/Retailer/Point"
              exact
              component={PointTransfers}
            />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}
